import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Save from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
}));

function MaskCelular(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskTelefone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskCpf(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskNascimento(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

MaskCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskCpf.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function PerfilUsuario() {
  const classes = useStyles();
  const labelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    nome: '',
    email: '',
    telefone: ' ',
    celular: ' ',
    nascimento: '',
    cpf: ' ',
    encarregado: '',
  });
  const [mount, setMount] = React.useState(false);
  const [errorUsername, setErrorUsername] = React.useState(false);
  const [errorNome, setErrorNome] = React.useState(false);
  const [errorNascimento, setErrorNascimento] = React.useState(false);

  React.useEffect(() => {
    setLabelWidth(85);

    if (!mount) {
      api.get('user-profile').then(response => {
        setMount(true);
        const user = {
          ...values,
          username: response.data.username,
          nome: response.data.nome,
          email: response.data.email ? response.data.email : '',
          telefone: response.data.telefone ? response.data.telefone : ' ',
          celular: response.data.celular ? response.data.celular : ' ',
          nascimento: response.data.nascimento ? response.data.nascimento : ' ',
          cpf: response.data.cpf ? response.data.cpf : ' ',
          encarregado: response.data.supervisor
            ? response.data.supervisor.nome
            : 'Não possui',
        };
        setValues(user);
      });
    }

    if (values.nome !== '') {
      setErrorNome(false);
    }
    if (values.username !== '') {
      setErrorUsername(false);
    }
    // eslint-disable-next-line radix
    if (values.nascimento !== '' && parseInt(values.nascimento[(0, 1)]) <= 31) {
      setErrorNascimento(false);
    }
  }, [errorNascimento, mount, values]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function validateUser() {
    if (values.nome === '') {
      return false;
    }
    if (values.username === '') {
      return false;
    }
    if (values.nascimento === '' || errorNascimento) {
      return false;
    }
    return true;
  }

  async function saveUser() {
    api
      .post('user-profile', values)
      .then(response => {
        if (response.data.ok) {
          toast.info(response.data.ok, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        }

        if (response.data.error) {
          toast.error(response.data.error, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        }
      })
      .catch(() => {
        toast.error('Ocorreu um erro e o perfil não foi salvo', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      });
    setLoading(false);
  }

  async function handleSaveUser() {
    setLoading(true);

    let validation = await validateUser();

    if (values.nome === '') {
      setErrorNome(true);
    } else {
      setErrorNome(false);
    }
    if (values.username === '') {
      setErrorUsername(true);
    } else {
      setErrorUsername(false);
    }
    if (values.nascimento === '') {
      setErrorNascimento(true);
    } else {
      setErrorNascimento(false);
    }
    // eslint-disable-next-line radix
    if (parseInt(values.nascimento[(0, 1)]) <= 31) {
      setErrorNascimento(false);
    } else {
      setErrorNascimento(true);
      validation = false;
    }

    if (validation === true) {
      saveUser();
    } else {
      setLoading(false);
      toast.error('Os campos em vermelho devem ser preenchidos', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  return (
    <div className={classes.root}>
      <>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Editar Perfil
            </Typography>
          </div>
          <div className={classes.spacer} />
          {loading === true ? (
            <div className={classes.actions}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <>
              <div className={classes.actions}>
                <Tooltip title="Salvar Perfil">
                  <IconButton
                    aria-label="Salvar Perfil"
                    onClick={() => handleSaveUser()}
                  >
                    <Save color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
        </Toolbar>
        <Paper className={classes.paper}>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="nome">
                    Nome
                  </InputLabel>
                  <OutlinedInput
                    id="nome"
                    error={errorNome}
                    value={values.nome}
                    onChange={handleChange('nome')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="username">
                    Usuário
                  </InputLabel>
                  <OutlinedInput
                    id="username"
                    error={errorUsername}
                    value={values.username}
                    onChange={handleChange('username')}
                    labelWidth={labelWidth}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="password">
                    Senha
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="email">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange('email')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="cpf">
                    CPF
                  </InputLabel>
                  <OutlinedInput
                    id="cpf"
                    value={values.cpf}
                    onChange={handleChange('cpf')}
                    labelWidth={labelWidth}
                    inputComponent={MaskCpf}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="nascimento">
                    Nascimento
                  </InputLabel>
                  <OutlinedInput
                    id="nascimento"
                    value={values.nascimento}
                    onChange={handleChange('nascimento')}
                    labelWidth={labelWidth}
                    inputComponent={MaskNascimento}
                    error={errorNascimento}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="telefone">
                    Telefone
                  </InputLabel>
                  <OutlinedInput
                    id="telefone"
                    value={values.telefone}
                    onChange={handleChange('telefone')}
                    labelWidth={labelWidth}
                    inputComponent={MaskTelefone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="celular">
                    Celular
                  </InputLabel>
                  <OutlinedInput
                    id="celular"
                    value={values.celular}
                    onChange={handleChange('celular')}
                    labelWidth={labelWidth}
                    inputComponent={MaskCelular}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="nome">
                    Encarregado
                  </InputLabel>
                  <OutlinedInput
                    id="encarregado"
                    value={values.encarregado}
                    labelWidth={labelWidth}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    </div>
  );
}
