import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

export default function CriarVereador() {
  const classes = useStyles();
  const labelRef = React.useRef(null);
  const labelWidth = 85;
  const [loading, setLoading] = React.useState(false);
  const [ufs, setUfs] = React.useState([]);
  const [localidades, setLocalidades] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [mount, setMount] = React.useState(false);
  const [create, setCreate] = React.useState(false);
  const [values, setValues] = React.useState({
    id_uf: '',
    id_localidade: '',
    nome: '',
    endereco: '',
    bairro: '',
    cep: '',
    cpf_cnpj: '',
    ie: '',
    telefone: '',
    observacao: '',
    tipo: 'Vereador',
  });
  const schema = Yup.object().shape({
    nome: Yup.string().required(),
  });

  function getUfs() {
    api.get('uf').then(response => {
      const listUfs = response.data.map(uf => {
        return {
          id: uf.id,
          sigla: uf.sigla,
        };
      });
      setUfs(listUfs);
    });
  }

  function getLocalidades(id) {
    api.get(`locale/${id}`).then(resp => {
      const listLocales = resp.data.map(l => {
        return {
          id: l.id,
          id_uf: l.id_uf,
          nome: l.nome,
        };
      });
      setLocalidades(listLocales);
    });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });

    if (name === 'id_uf') {
      getLocalidades(event.target.value);
    }
  };

  React.useEffect(() => {
    if (!mount) {
      api.get('functions').then(response => {
        setCreate(response.data.vereador_criar);
      });

      setMount(true);
    }
    if (ufs.length === 0) {
      getUfs();
    }
  }, [mount, ufs]);

  function validation() {
    if (
      values.id_uf === '' ||
      values.id_localidade === '' ||
      values.cpf_cnpj === '' ||
      values.nome === '' ||
      values.telefone === ''
    ) {
      return false;
    }
    return true;
  }

  function handleSave() {
    setLoading(true);

    const valid = validation();

    if (valid) {
      try {
        api.post('assemblymans', { values }).then(response => {
          if (response.data.error) {
            toast.error(response.data.error, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }

          if (response.data.nome) {
            toast.info(`${response.data.nome} criado com sucesso`, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
            history.push('/vereadores');
          }
        });
      } catch (err) {
        toast.error('Erro ao salvar vereador', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    } else {
      setLoading(false);
      setError(true);
      toast.error('Campos obrigatorios devem ser preenchidos', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  return (
    <div className={classes.root}>
      {create ? (
        <Paper className={classes.paper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Criar Vereador
              </Typography>
            </div>
            <div className={classes.spacer} />
            {loading === true ? (
              <div className={classes.actions}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <>
                <div className={classes.actions}>
                  <Link to="/vereadores">
                    <Tooltip title="Cancelar">
                      <IconButton aria-label="Cancelar">
                        <Close color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </div>
                <div className={classes.actions}>
                  <Tooltip title="Salvar Usuário">
                    <IconButton
                      aria-label="Salvar Usuário"
                      onClick={() => handleSave()}
                    >
                      <Save color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )}
          </Toolbar>
          <form
            className={classes.container}
            schema={schema}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required
                  aria-describedby="nome"
                  error={error}
                >
                  <InputLabel ref={labelRef} htmlFor="nome">
                    Nome
                  </InputLabel>
                  <OutlinedInput
                    id="nome"
                    name="nome"
                    error={error && values.nome === ''}
                    value={values.nome}
                    onChange={handleChange('nome')}
                    labelWidth={labelWidth}
                  />
                  {error && values.nome === '' && (
                    <FormHelperText>* Campo obrigatorio</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="endereco">
                    Endereço
                  </InputLabel>
                  <OutlinedInput
                    id="endereco"
                    // error={errorNome}
                    value={values.endereco}
                    onChange={handleChange('endereco')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="bairro">
                    Bairro
                  </InputLabel>
                  <OutlinedInput
                    id="bairro"
                    // error={errorNome}
                    value={values.bairro}
                    onChange={handleChange('bairro')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel ref={labelRef} htmlFor="cep">
                    CEP
                  </InputLabel>
                  <OutlinedInput
                    id="cep"
                    // error={errorNome}
                    value={values.cep}
                    onChange={handleChange('cep')}
                    labelWidth={labelWidth}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  id="id_uf"
                  select
                  // error={errorUf}
                  label="UF"
                  className={classes.formControl}
                  value={values.id_uf}
                  onChange={handleChange('id_uf')}
                  error={error && values.id_uf === ''}
                  required
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  helperText={error && '* Campo obrigatorio'}
                >
                  <option key="0" value="">
                    {''}
                  </option>
                  {ufs.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.sigla}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="id_localidade"
                  select
                  required
                  error={error && values.id_localidade === ''}
                  disabled={values.id_uf === ''}
                  label="Cidade"
                  className={classes.formControl}
                  value={values.id_localidade}
                  onChange={handleChange('id_localidade')}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  helperText={error && '* Campo obrigatorio'}
                >
                  <option key="0" value="">
                    {' '}
                  </option>
                  {localidades.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.nome}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required
                  error={error}
                >
                  <InputLabel ref={labelRef} htmlFor="cpf_cnpj">
                    CPF
                  </InputLabel>
                  <OutlinedInput
                    id="cpf_cnpj"
                    required
                    error={error && values.cpf_cnpj === ''}
                    value={values.cpf_cnpj}
                    onChange={handleChange('cpf_cnpj')}
                    labelWidth={labelWidth}
                  />
                  {error && values.cpf_cnpj === '' && (
                    <FormHelperText>* Campo obrigatorio</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required
                  error={error}
                >
                  <InputLabel ref={labelRef} htmlFor="telefone">
                    Celular
                  </InputLabel>
                  <OutlinedInput
                    id="telefone"
                    value={values.telefone}
                    error={error && values.telefone === ''}
                    onChange={handleChange('telefone')}
                    labelWidth={labelWidth}
                  />
                  {error && values.telefone === '' && (
                    <FormHelperText>* Campo obrigatorio</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="observação"
                  label="Observação"
                  className={classes.formControl}
                  multiline
                  rowsMax="4"
                  value={values.observacao}
                  onChange={handleChange('observacao')}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </div>
  );
}
