import styled from 'styled-components';

export const Container = styled.div`
  height: '100vh';
  width: '100%';
  padding-top: 60px;
`;

export const Logo = styled.div`
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  font: 16px/24px sans-serif;
  padding: 12px 24px;
  position: relative;
`;

export const FilterForm = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255);
`;

export const ContainerFilter = styled.div`
  margin-top: 75px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;

  button {
    margin: 10px;
    border: 0;
    background: none;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
  }
`;
