import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import Grow from '@material-ui/core/Grow';
import SearchIcon from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Content, Pointer } from './styles';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Username',
  },
  {
    id: 'perfil',
    numeric: false,
    disablePadding: true,
    label: 'Perfil',
  },
  {
    id: 'encarregado',
    numeric: true,
    disablePadding: false,
    label: 'Encarregado',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'uf',
    numeric: true,
    disablePadding: false,
    label: 'UF',
  },
  {
    id: 'municipio',
    numeric: false,
    disablePadding: true,
    label: 'Municipio',
  },
  {
    id: 'editar',
    numeric: false,
    disablePadding: true,
    label: 'Editar',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TablePagination.defaultProps = {
  component: TableCell,
  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
  labelRowsPerPage: 'Quantidade por página:',
  rowsPerPageOptions: [5, 10, 25],
};

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rootTitle: {
    padding: '2px 4px',
    display: 'flex',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  transition: {
    transition: 'width 2s',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

export default function Usuarios() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [initial, setInitial] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [mount, setMount] = React.useState(false);
  const [func, setFunc] = React.useState({
    view: false,
    edit: false,
    create: false,
    status: false,
  });
  const [search, setSearch] = React.useState(false);

  function loadUsers() {
    api.get('users').then(response => {
      const users = response.data.map(u => {
        return {
          id: u.id,
          nome: u.nome,
          username: u.username,
          perfil: u.perfil.nome,
          encarregado: u.supervisor ? u.supervisor.nome : '',
          ativo: u.ativo,
          email: u.email,
          localidade: u.localidade.nome,
          uf: u.uf.nome,
        };
      });
      setInitial(users);
    });
  }

  onchange = e => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (!mount) {
      loadUsers();

      api.get('functions').then(response => {
        setFunc({
          ...func,
          view: response.data.usuarios_ver,
          status: response.data.usuarios_status,
          edit: response.data.usuarios_editar,
          criar: response.data.usuarios_criar,
        });
      });

      setMount(true);
    }
    let newRow = initial;
    newRow = newRow.filter(item => {
      return item.nome.toLowerCase().search(filter.toLowerCase()) !== -1;
    });
    setRows(newRow);
  }, [filter, func, initial, mount]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function handleEdit() {
    toast.error('Você não tem permissão para editar um usuário', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  }

  function handleChangeStatus(id) {
    if (!func.status) {
      toast.error(
        'Você não tem permissão para alterar o status de um usuário',
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        }
      );
    } else {
      api
        .delete(`users/${id}`)
        .then(response => {
          loadUsers();
          if (response.data.ok) {
            toast.info(response.data.ok, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
        })
        .catch(() => {
          toast.error('Erro ao deletar usuário', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    }
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {func.view ? (
        <>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Lista de Usuários
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              {search && (
                <Grow
                  in={search}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(search ? { timeout: 1000 } : {})}
                >
                  <Paper className={classes.rootTitle}>
                    <InputBase
                      className={classes.input}
                      placeholder="Buscar usuário..."
                      inputProps={{ 'aria-label': 'buscar usuário' }}
                      onChange={onchange}
                    />
                    <IconButton
                      className={classes.iconButton}
                      aria-label="Fechar"
                      // eslint-disable-next-line no-sequences
                      onClick={() => (setSearch(false), setFilter(''))}
                    >
                      <Close color="secondary" />
                    </IconButton>
                  </Paper>
                </Grow>
              )}
              {!search && (
                <Grow
                  in={!search}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(!search ? { timeout: 1000 } : {})}
                >
                  <Tooltip title="Procurar" onClick={() => setSearch(true)}>
                    <div className={classes.transition}>
                      <IconButton aria-label="Procurar">
                        <SearchIcon color="primary" />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grow>
              )}
            </div>
            {func.criar && (
              <div className={classes.actions}>
                <Link to="/usuarios/criar">
                  <Tooltip title="Novo Usuário">
                    <IconButton aria-label="Novo Usuário">
                      <Add color="primary" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </div>
            )}
          </Toolbar>
          <Paper className={classes.paper}>
            {rows.length < 1 ? (
              <Content>
                <CircularProgress className={classes.progress} />
              </Content>
            ) : (
              <>
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />

                    <TableBody>
                      {stableSort(rows, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell align="center">
                                {row.ativo ? (
                                  <Pointer>
                                    <LockOpen
                                      color="primary"
                                      onClick={() => handleChangeStatus(row.id)}
                                    />
                                  </Pointer>
                                ) : (
                                  <Pointer>
                                    <Lock
                                      color="secondary"
                                      onClick={() => handleChangeStatus(row.id)}
                                    />
                                  </Pointer>
                                )}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="left"
                              >
                                {row.nome}
                              </TableCell>
                              <TableCell align="center">
                                {row.username}
                              </TableCell>
                              <TableCell align="center">{row.perfil}</TableCell>
                              <TableCell align="center">
                                {row.encarregado}
                              </TableCell>
                              <TableCell align="center">{row.email}</TableCell>
                              <TableCell align="center">{row.uf}</TableCell>
                              <TableCell align="center">
                                {row.localidade}
                              </TableCell>
                              <TableCell align="center">
                                {func.edit ? (
                                  <Link to={`usuarios/editar/${row.id}`}>
                                    <Edit color="primary" />
                                  </Link>
                                ) : (
                                  <Edit
                                    color="primary"
                                    onClick={() => handleEdit()}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Compactar"
          />
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </div>
  );
}
