import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import history from '~/services/history';
import api from '~/services/api';

import { Wrapper, ButtonContainer } from '../styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function PerfilCriar() {
  const classes = useStyles();
  const [values, setValues] = React.useState({ name: '' });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleSubmit() {
    api
      .post('profile', {
        nome: values.name,
      })
      .then(resp => {
        // eslint-disable-next-line no-unused-expressions
        resp.data.error
          ? toast.error(resp.data.error)
          : history.push('/perfis', {
              id: resp.data.id,
              nome: resp.data.nome,
            });
      })
      .catch(() => {
        toast.error('Falha ao criar perfil');
      });
  }

  return (
    <Wrapper>
      <form>
        <TextField
          id="standard-name"
          label="Name"
          className={classes.textField}
          value={values.name}
          onChange={handleChange('name')}
          margin="normal"
          name="nome"
          variant="outlined"
        />

        <ButtonContainer>
          <button type="button">
            <Link to="/perfis">
              <Close color="secondary" />
            </Link>
          </button>
          <button type="button">
            <Check color="primary" onClick={() => handleSubmit()} />
          </button>
        </ButtonContainer>
      </form>
    </Wrapper>
  );
}
