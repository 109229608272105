import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 20px;
`;

export const Pointer = styled.div`
  cursor: pointer;
`;
