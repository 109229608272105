/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useField } from '@rocketseat/unform';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

import logoAtividades from '~/assets/logo-atividades.jpg';

import { Container } from '../styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

export default function CriarAtividade(props) {
  const classes = useStyles();
  const labelRef = React.useRef(null);
  const labelWidth = 85;
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    nome: '',
  });
  const { registerField } = useField('img_atividade');

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [mount, setMount] = useState(false);
  const [create, setCreate] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (!mount) {
      api.get('functions').then(response => {
        setCreate(response.data.atividades_criar);
      });

      setMount(true);
    }
    // eslint-disable-next-line react/prop-types
    if (props.match.params.id) {
      api.get(`activities?id=${props.match.params.id}`).then(response => {
        setValues({ nome: response.data.nome });
        setPreview(response.data.img.url);
        setFile(response.data.img.id);
      });
    }

    if (ref.current) {
      registerField({
        name: 'img_atividade_id',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [mount, props, ref, registerField]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleSave() {
    setLoading(true);
    const atividade = {
      id: props.match.params.id,
      nome: values.nome,
      img_id: file,
    };

    if (preview === null || values.nome === '') {
      toast.error('Necessário preencher imagem e nome', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      try {
        api.post('activities', { atividade }).then(response => {
          toast.info(`Atividade ${response.data.nome} salva com sucesso`, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
          history.push('/atividades');
        });
      } catch (err) {
        toast.error('Erro ao salvar atividade', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }

    setLoading(false);
  }

  async function handleChangeImg(e) {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    const response = await api.post('activity-file', data);

    const { id, url } = response.data;

    setFile(id);
    setPreview(url);
  }

  return (
    <div className={classes.root}>
      {create ? (
        <>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Criar Atividade
              </Typography>
            </div>
            <div className={classes.spacer} />
            {loading === true ? (
              <div className={classes.actions}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <>
                <div className={classes.actions}>
                  <Link to="/atividades">
                    <Tooltip title="Cancelar">
                      <IconButton aria-label="Cancelar">
                        <Close color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </div>
                <div className={classes.actions}>
                  <Tooltip title="Salvar Atividade">
                    <IconButton
                      aria-label="Salvar Atividade"
                      onClick={() => handleSave()}
                    >
                      <Save color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )}
          </Toolbar>
          <Paper className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3} />
                <Grid item xs={12} sm={1}>
                  <Container>
                    <label htmlFor="img_atividade">
                      <img src={preview || logoAtividades} alt="" />

                      <input
                        type="file"
                        id="img_atividade"
                        accept="image/*"
                        data-file={file}
                        onChange={handleChangeImg}
                        ref={ref}
                      />
                    </label>
                  </Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="nome">
                      Nome
                    </InputLabel>
                    <OutlinedInput
                      id="nome"
                      value={values.nome}
                      onChange={handleChange('nome')}
                      labelWidth={labelWidth}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </div>
  );
}
