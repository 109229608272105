/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import { toast } from 'react-toastify';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  spacer: {
    flex: '1 1 50%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function Secretarias(props) {
  const { id } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mount, setMount] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = items => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.nome} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  function handleSaveSecretariats() {
    setLoading(true);
    if (right.length === 0) {
      setLoading(false);
      toast.error('Nenhuma secretaria selecionada', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      try {
        setLoading(false);
        api
          .post('secretariats-groups', {
            id_grupo: id,
            secretarias: right,
          })
          .then(response => {
            toast.info(response.data.ok, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          });
      } catch (err) {
        setLoading(false);
        toast.error('Erro ao salvar secretarias', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  }

  useEffect(() => {
    if (!mount) {
      api.get(`secretariats/${id}`).then(response => {
        const listRight = response.data.right.map(a => {
          return {
            id: a.id,
            nome: a.nome,
          };
        });
        setRight(listRight);
        const listLeft = response.data.left.map(a => {
          return {
            id: a.id,
            nome: a.nome,
          };
        });
        setLeft(listLeft);
      });
      setMount(true);
    }
  }, [left, mount, id, right]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Editar Secretarias
            </Typography>
          </div>
          <div className={classes.spacer} />
          {loading === true ? (
            <div className={classes.actions}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <>
              <div className={classes.actions}>
                <Link to="/prefeituras">
                  <Tooltip title="Cancelar">
                    <IconButton aria-label="Cancelar">
                      <Close color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </div>
              <div className={classes.actions}>
                <Tooltip title="Salvar Secretarias">
                  <IconButton
                    aria-label="Salvar Secretarias"
                    onClick={() => handleSaveSecretariats()}
                  >
                    <Save color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
        </Toolbar>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right)}</Grid>
        </Grid>
      </Paper>
    </div>
  );
}
