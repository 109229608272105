import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 20px;
`;

export const Pointer = styled.div`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;

export const ButtonContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  text-align: center;

  button {
    margin: 10px;
    border: 0;
    background: none;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const ContainerFilter = styled.div`
  margin-top: 75px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
`;
