/* eslint-disable radix */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import Geocode from 'react-geocode';
import history from '~/services/history';

import api from '~/services/api';

Geocode.setApiKey('AIzaSyDSp_yy3zd-yk5M1MUO67MXydvhkL8O2P8');
Geocode.setLanguage('pt_br');
Geocode.setRegion('br');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  spacer: {
    flex: '1 1 30%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '96%',
  },
  menu: {
    width: 200,
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function Criar() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [mount, setMount] = React.useState(false);
  const [activities, setActivities] = React.useState([]);
  const [create, setCreate] = React.useState(false);
  const [errorAtividade, setErrorAtividade] = React.useState(false);
  const [errorEndereco, setErrorEndereco] = React.useState(false);
  const [errorReferencia, setErrorReferencia] = React.useState(false);
  const [errorDescricao, setErrorDescricao] = React.useState(false);
  const [values, setValues] = React.useState({
    id_atividade: '',
    endereco: '',
    referencia: '',
    descricao: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleSave() {
    if (profile.id === 1) {
      toast.error(
        'Seu grupo não possui redirecionamento para criação de solicitação',
        {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        }
      );
    } else {
      if (values.id_atividade === '') {
        setErrorAtividade(true);
      } else {
        setErrorAtividade(false);
      }
      if (values.endereco === '') {
        setErrorEndereco(true);
      } else {
        setErrorEndereco(false);
      }
      if (values.referencia === '') {
        setErrorReferencia(true);
      } else {
        setErrorReferencia(false);
      }
      if (values.descricao === '') {
        setErrorDescricao(true);
      } else {
        setErrorDescricao(false);
      }

      if (
        values.id_atividade === '' ||
        values.endereco === '' ||
        values.referencia === '' ||
        values.descricao === ''
      ) {
        toast.error('Os campos em vermelho deve ser preenchidos', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      } else {
        Geocode.fromAddress(values.endereco).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            const solicitacao = {
              id_usuario: parseInt(profile.id),
              id_atividade: parseInt(values.id_atividade),
              id_posto: parseInt(values.id_posto),
              id_secretaria: parseInt(values.id_secretaria),
              endereco: values.endereco,
              referencia: values.referencia,
              descricao: values.descricao,
              lat,
              lng,
            };

            api.post('ticket?web=true', solicitacao).then(resp => {
              if (resp.data.ok) {
                toast.info(resp.data.ok, {
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
                history.push('/solicitacoes');
              }

              if (resp.data.error) {
                toast.error(resp.data.error, {
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
              }
            });
          },
          error => {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        );
      }
    }
  }

  useEffect(() => {
    if (!mount) {
      api.get('functions').then(response => {
        setCreate(response.data.solicitacoes_criar);
      });
      api.get(`activities-groups?user=${profile.id}`).then(a => {
        setActivities(a.data);
      });

      setMount(true);
    }
  }, [mount, profile.id]);

  return (
    <Paper className={classes.paper}>
      {create ? (
        <>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Detalhes do Chamado
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/solicitacoes">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
            <div className={classes.actions}>
              <Tooltip title="Salvar Solicitação">
                <IconButton
                  aria-label="Salvar Solicitação"
                  onClick={() => handleSave()}
                >
                  <Save color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid item xs={12} sm={4}>
              <TextField
                label="Aberto Por"
                className={classes.textField}
                value={profile.nome}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label="Atividade"
                className={classes.textField}
                value={values.id_atividade}
                onChange={handleChange('id_atividade')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                error={errorAtividade}
              >
                <option key="0" value="">
                  {''}
                </option>
                {activities.map(activity => (
                  <option
                    key={activity.atividade.id}
                    value={activity.atividade.id}
                  >
                    {activity.atividade.nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Endereço"
                className={classes.textField}
                value={values.endereco}
                margin="normal"
                variant="outlined"
                onChange={handleChange('endereco')}
                error={errorEndereco}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Referência"
                className={classes.textField}
                value={values.referencia}
                margin="normal"
                variant="outlined"
                onChange={handleChange('referencia')}
                error={errorReferencia}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Descrição"
                multiline
                className={classes.textField}
                value={values.descricao}
                margin="normal"
                variant="outlined"
                onChange={handleChange('descricao')}
                error={errorDescricao}
              />
            </Grid>
          </form>
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </Paper>
  );
}
