import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grow from '@material-ui/core/Grow';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

import { Content, Pointer } from './styles';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Ativar/Desativar',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'criado_por',
    numeric: true,
    disablePadding: false,
    label: 'Criado Por',
  },
  {
    id: 'editar',
    numeric: true,
    disablePadding: false,
    label: 'Editar',
  },
  {
    id: 'delete',
    numeric: true,
    disablePadding: false,
    label: 'Delete',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TablePagination.defaultProps = {
  component: TableCell,
  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
  labelRowsPerPage: 'Quantidade por página:',
  rowsPerPageOptions: [5, 10, 25],
};

EnhancedTableHead.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  spacer: {
    flex: '1 1 60%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

export default function Perfis() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [initial, setInitial] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [newProfile, setNewProfile] = React.useState({ open: false, nome: '' });
  const [mount, setMount] = React.useState(false);
  const [func, setFunc] = React.useState({
    view: false,
    edit: false,
    create: false,
    status: false,
    delete: false,
  });

  const handleChangePerfil = name => event => {
    setNewProfile({ ...newProfile, [name]: event.target.value || '' });
  };

  const handleClickOpen = () => {
    setNewProfile({ ...newProfile, open: true });
  };

  const handleClose = () => {
    setNewProfile({ ...newProfile, open: false, nome: '' });
  };

  async function loadProfiles() {
    api.get('profile').then(response => {
      const profile = response.data.map(p => {
        return {
          id: p.id,
          name: p.nome,
          qtd: p.qtd,
          created_by: p.criado.nome,
          ativo: p.ativo,
        };
      });
      setInitial(profile);
    });
  }

  const onchangeFilter = e => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    if (!mount) {
      loadProfiles();

      api.get('functions').then(response => {
        setFunc({
          ...func,
          view: response.data.perfis_ver,
          status: response.data.perfis_status,
          edit: response.data.perfis_editar,
          create: response.data.perfis_criar,
          delete: response.data.perfis_deletar,
        });
      });

      setMount(true);
    }
    let newRow = initial;
    newRow = newRow.filter(item => {
      return item.name.toLowerCase().search(filter.toLowerCase()) !== -1;
    });
    setRows(newRow);
  }, [filter, func, initial, mount]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function handleChangeStatus(id) {
    if (!func.status) {
      toast.error('Você não tem permissão para ativar/desativar um perfil', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      try {
        api.put(`profile/${id}`).then(response => {
          if (response.data.error) {
            toast.error(response.data.error, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }

          if (response.data.nome) {
            toast.info(`${response.data.nome} atualizado com sucesso`, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          loadProfiles();
        });
      } catch (err) {
        toast.error('Erro ao atualizar', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  }

  function handleChangeEdit(id) {
    if (id === 1) {
      toast.error('Perfil Master não pode ser editado', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else if (!func.edit) {
      toast.error('Você não tem permissão para editar um perfil', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      history.push(`/perfis/editar/${id}`);
    }
  }

  function handleChangeDelete(id) {
    if (!func.delete) {
      toast.error('Você não tem permissão para deletar um perfil', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      try {
        api.delete(`profile/${id}`).then(response => {
          if (response.data.error) {
            toast.error(response.data.error, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }

          if (response.data.ok) {
            toast.info('Perfil deletado com sucesso', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          loadProfiles();
        });
      } catch (err) {
        toast.error('Erro ao deletar', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  }

  const handleSave = () => {
    api
      .post('profile', {
        nome: newProfile.nome,
      })
      .then(resp => {
        loadProfiles();
        setNewProfile({ ...newProfile, open: false, nome: '' });
        if (resp.data.ok) {
          toast.info(resp.data.ok);
        }
        if (resp.data.error) {
          toast.error(resp.data.error);
        }
      })
      .catch(() => {
        toast.error('Falha ao criar perfil');
      });
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      {func.view ? (
        <div className={classes.root}>
          {rows.length < 1 ? (
            <Content>
              <CircularProgress className={classes.progress} />
            </Content>
          ) : (
            <>
              <Toolbar>
                <div className={classes.title}>
                  <Typography variant="h6" id="tableTitle">
                    Lista de Perfis
                  </Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                  {search && (
                    <Grow
                      in={search}
                      style={{ transformOrigin: '0 0 0' }}
                      {...(search ? { timeout: 1000 } : {})}
                    >
                      <Paper className={classes.rootTitle}>
                        <InputBase
                          className={classes.input}
                          placeholder="Buscar perfil..."
                          inputProps={{ 'aria-label': 'buscar usuário' }}
                          onChange={onchangeFilter}
                        />
                        <IconButton
                          className={classes.iconButton}
                          aria-label="Fechar"
                          // eslint-disable-next-line no-sequences
                          onClick={() => (setSearch(false), setFilter(''))}
                        >
                          <Close color="secondary" />
                        </IconButton>
                      </Paper>
                    </Grow>
                  )}
                  {!search && (
                    <Grow
                      in={!search}
                      style={{ transformOrigin: '0 0 0' }}
                      {...(!search ? { timeout: 1000 } : {})}
                    >
                      <Tooltip title="Procurar" onClick={() => setSearch(true)}>
                        <div className={classes.transition}>
                          <IconButton aria-label="Procurar">
                            <SearchIcon color="primary" />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grow>
                  )}
                </div>
                {func.create && (
                  <div className={classes.actions}>
                    <Tooltip title="Novo Perfil">
                      <IconButton
                        aria-label="Novo Perfil"
                        onClick={handleClickOpen}
                      >
                        <Add color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Dialog
                      disableBackdropClick
                      disableEscapeKeyDown
                      open={newProfile.open}
                      onClose={handleClose}
                    >
                      <DialogTitle>Dê nome ao perfil</DialogTitle>
                      <DialogContent>
                        <form className={classes.container}>
                          <TextField
                            id="nome"
                            label="Nome"
                            className={classes.formControl}
                            value={newProfile.nome}
                            onChange={handleChangePerfil('nome')}
                            margin="normal"
                            name="nome"
                            variant="outlined"
                          />
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancelar
                        </Button>
                        <Button onClick={handleSave} color="primary">
                          Criar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
              </Toolbar>
              <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />

                    <TableBody>
                      {stableSort(rows, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell align="center">
                                {row.ativo ? (
                                  <Pointer>
                                    <Close
                                      color="secondary"
                                      onClick={() => handleChangeStatus(row.id)}
                                    />
                                  </Pointer>
                                ) : (
                                  <Pointer>
                                    <Check
                                      color="primary"
                                      onClick={() => handleChangeStatus(row.id)}
                                    />
                                  </Pointer>
                                )}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {row.name}
                              </TableCell>

                              <TableCell align="center">
                                {row.created_by}
                              </TableCell>
                              <TableCell align="center">
                                <Edit
                                  color="primary"
                                  onClick={() => handleChangeEdit(row.id)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Delete
                                  color="secondary"
                                  onClick={() => handleChangeDelete(row.id)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
              <FormControlLabel
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Compactar"
              />
            </>
          )}
        </div>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </>
  );
}
