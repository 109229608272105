import styled from 'styled-components';
import { darken } from 'polished';

export const Background = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  padding: 100px 0;
  color: white;
  /* background: url('http://www.alocidadao.com/alocidadao/includes/images/background.jpg')
    no-repeat bottom center scroll;*/
  background: url('static/media/background.jpg') no-repeat bottom center scroll;
  background-position: 30% 45%;
  background-size: cover;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 350px;
  text-align: center;
  background: rgba(255, 255, 255);
  padding: 20px;
  box-shadow: inset 0 0 1em grey, 0 0 1em grey;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      width: 100%;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #3533ee;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.1, '#3533ee')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
