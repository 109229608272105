/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#000',
  },
  paperFull: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#FFF',
    backgroundColor: '#3f51b5',
  },
  paperRadio: {
    textAlign: 'center',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

export default function Vereadores(props) {
  const { id } = props.match.params;
  const classes = useStyles();
  const [profile, setProfile] = React.useState('');
  const [edit, setEdit] = React.useState(false);
  const [mount, setMount] = React.useState(false);
  const [func, setFunc] = React.useState(false);
  const [mountFunc, setMountFunc] = React.useState(false);
  const [values, setValues] = React.useState({
    id_perfil: id,
    executor: false,
    login_web: false,
    login_app: false,
    dashboard_numero_de_prefeituras: false,
    dashboard_numero_de_vereadores: false,
    dashboard_numero_de_usuarios: false,
    dashboard_numero_de_usuarios_app: false,
    dashboard_numero_de_solicitacoes: false,
    dashboard_solicitacoes_abertas: false,
    dashboard_solicitacoes_concluidas: false,
    solicitacoes_criar: false,
    solicitacoes_visualizar: false,
    solicitacoes_editar: false,
    solicitacoes_despachar: false,
    mapa_ver: false,
    usuarios_ver: false,
    usuarios_status: false,
    usuarios_editar: false,
    usuarios_criar: false,
    perfis_ver: false,
    perfis_status: false,
    perfis_editar: false,
    perfis_deletar: false,
    perfis_criar: false,
    prefeituras_ver: false,
    prefeituras_status: false,
    prefeituras_editar: false,
    prefeituras_deletar: false,
    prefeituras_criar: false,
    vereador_ver: false,
    vereador_status: false,
    vereador_editar: false,
    vereador_deletar: false,
    vereador_criar: false,
    atividades_ver: false,
    atividades_status: false,
    atividades_editar: false,
    atividades_deletar: false,
    atividades_criar: false,
  });

  const handleChangeRadio = name => event => {
    setValues({ ...values, [name]: JSON.parse(event.target.value) });
  };

  function handleEditName() {
    setEdit(!edit);
  }

  function handleSave() {
    if (profile === '') {
      toast.error('O nome do Perfil deve ser preenchido', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      api
        .put(`profile/${id}`, { profile })
        .then(() => {
          api
            .post('functions', values)
            .then(response => {
              toast.info(response.data.ok, {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
              });
              history.push('/perfis');
            })
            .catch(() => {
              toast.error('Ocorreu um erro ao salvar as funcionalidades', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
              });
            });
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao salvar o perfil', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    }
  }

  const handleChange = event => {
    setProfile(event.target.value);
  };

  useEffect(() => {
    function getProfile() {
      api.get(`profile/${id}`).then(response => {
        setProfile(response.data.nome);
      });
      setMount(true);
    }
    if (profile === '' && !mount) {
      getProfile();

      api.get('functions').then(response => {
        setFunc(response.data.perfis_editar);
      });
    }

    function getFunctions() {
      api.get(`functions/${id}`).then(response => {
        if (response.data !== null) {
          setValues(response.data);
        }
      });
      setMountFunc(true);
    }
    if (profile === '' && !mountFunc) {
      getFunctions();
    }
  }, [mount, mountFunc, profile, id]);

  return (
    <div className={classes.root}>
      {func ? (
        <>
          <Toolbar>
            <div className={classes.title}>
              {edit ? (
                <>
                  <TextField
                    id="nome"
                    label="Nome"
                    value={profile}
                    className={classes.textField}
                    name="nome"
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                  />
                  <Close color="secondary" onClick={handleEditName} />
                </>
              ) : (
                <Typography variant="h6" id="tableTitle">
                  Perfil {profile}{' '}
                  <Edit color="primary" onClick={handleEditName} />
                </Typography>
              )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/perfis">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
            <div className={classes.actions}>
              <Tooltip title="Salvar Funcionalidades">
                <IconButton
                  aria-label="Salvar Funcionalidades"
                  onClick={handleSave}
                >
                  <Save color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Executor</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Pode executar OS</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.executor === true}
                  onChange={handleChangeRadio('executor')}
                  name="executor"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.executor === false}
                  onChange={handleChangeRadio('executor')}
                  name="executor"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Login</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Login Web</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.login_web === true}
                  onChange={handleChangeRadio('login_web')}
                  name="login_web"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.login_web === false}
                  onChange={handleChangeRadio('login_web')}
                  name="login_web"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Login App</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.login_app === true}
                  onChange={handleChangeRadio('login_app')}
                  name="login_app"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.login_app === false}
                  onChange={handleChangeRadio('login_app')}
                  name="login_app"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Dashboard</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Numero de Prefeituras</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_prefeituras === true}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_prefeituras'
                  )}
                  name="dashboard_numero_de_grupos"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_prefeituras === false}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_prefeituras'
                  )}
                  name="dashboard_numero_de_grupos"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Numero de Vereadores</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_vereadores === true}
                  onChange={handleChangeRadio('dashboard_numero_de_vereadores')}
                  name="dashboard_numero_de_grupos"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_vereadores === false}
                  onChange={handleChangeRadio('dashboard_numero_de_vereadores')}
                  name="dashboard_numero_de_grupos"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Numero de Usuários</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_usuarios === true}
                  onChange={handleChangeRadio('dashboard_numero_de_usuarios')}
                  name="dashboard_numero_de_usuarios"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_usuarios === false}
                  onChange={handleChangeRadio('dashboard_numero_de_usuarios')}
                  name="dashboard_numero_de_usuarios"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Usuários de Aplicativo</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_usuarios_app === true}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_usuarios_app'
                  )}
                  name="dashboard_numero_de_usuarios"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_usuarios_app === false}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_usuarios_app'
                  )}
                  name="dashboard_numero_de_usuarios"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Numero de Solicitações</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_solicitacoes === true}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_solicitacoes'
                  )}
                  name="dashboard_numero_de_solicitacoes"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_numero_de_solicitacoes === false}
                  onChange={handleChangeRadio(
                    'dashboard_numero_de_solicitacoes'
                  )}
                  name="dashboard_numero_de_solicitacoes"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Solicitações Abertas</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_solicitacoes_abertas === true}
                  onChange={handleChangeRadio('dashboard_solicitacoes_abertas')}
                  name="dashboard_solicitacoes_abertas"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_solicitacoes_abertas === false}
                  onChange={handleChangeRadio('dashboard_solicitacoes_abertas')}
                  name="dashboard_solicitacoes_abertas"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Solicitações Concluidas</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_solicitacoes_concluidas === true}
                  onChange={handleChangeRadio(
                    'dashboard_solicitacoes_concluidas'
                  )}
                  name="dashboard_solicitacoes_concluidas"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.dashboard_solicitacoes_concluidas === false}
                  onChange={handleChangeRadio(
                    'dashboard_solicitacoes_concluidas'
                  )}
                  name="dashboard_solicitacoes_concluidas"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Solicitações</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_criar === true}
                  onChange={handleChangeRadio('solicitacoes_criar')}
                  name="solicitacoes_criar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_criar === false}
                  onChange={handleChangeRadio('solicitacoes_criar')}
                  name="solicitacoes_criar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_editar === true}
                  onChange={handleChangeRadio('solicitacoes_editar')}
                  name="solicitacoes_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_editar === false}
                  onChange={handleChangeRadio('solicitacoes_editar')}
                  name="solicitacoes_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Despachar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_despachar === true}
                  onChange={handleChangeRadio('solicitacoes_despachar')}
                  name="solicitacoes_despachar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.solicitacoes_despachar === false}
                  onChange={handleChangeRadio('solicitacoes_despachar')}
                  name="solicitacoes_despachar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Usuários</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ver Usuários</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_ver === true}
                  onChange={handleChangeRadio('usuarios_ver')}
                  name="usuarios_ver"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_ver === false}
                  onChange={handleChangeRadio('usuarios_ver')}
                  name="usuarios_ver"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ativiar/Desativar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_status === true}
                  onChange={handleChangeRadio('usuarios_status')}
                  name="usuarios_status"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_status === false}
                  onChange={handleChangeRadio('usuarios_status')}
                  name="usuarios_status"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_criar === true}
                  onChange={handleChangeRadio('usuarios_criar')}
                  name="usuarios_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_criar === false}
                  onChange={handleChangeRadio('usuarios_criar')}
                  name="usuarios_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_editar === true}
                  onChange={handleChangeRadio('usuarios_editar')}
                  name="usuarios_deletar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.usuarios_editar === false}
                  onChange={handleChangeRadio('usuarios_editar')}
                  name="usuarios_deletar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Perfis</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ver Perfis</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_ver === true}
                  onChange={handleChangeRadio('perfis_ver')}
                  name="perfis_ver"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_ver === false}
                  onChange={handleChangeRadio('perfis_ver')}
                  name="perfis_ver"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ativiar/Desativar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_status === true}
                  onChange={handleChangeRadio('perfis_status')}
                  name="perfis_status"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_status === false}
                  onChange={handleChangeRadio('perfis_status')}
                  name="perfis_status"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_criar === true}
                  onChange={handleChangeRadio('perfis_criar')}
                  name="perfis_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_criar === false}
                  onChange={handleChangeRadio('perfis_criar')}
                  name="perfis_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_editar === true}
                  onChange={handleChangeRadio('perfis_editar')}
                  name="perfis_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_editar === false}
                  onChange={handleChangeRadio('perfis_editar')}
                  name="perfis_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Deletar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_deletar === true}
                  onChange={handleChangeRadio('perfis_deletar')}
                  name="perfis_deletar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.perfis_deletar === false}
                  onChange={handleChangeRadio('perfis_deletar')}
                  name="perfis_deletar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Prefeituras</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ver Prefeituras</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_ver === true}
                  onChange={handleChangeRadio('prefeituras_ver')}
                  name="prefeituras_ver"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_ver === false}
                  onChange={handleChangeRadio('prefeituras_ver')}
                  name="prefeituras_ver"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ativiar/Desativar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_status === true}
                  onChange={handleChangeRadio('prefeituras_status')}
                  name="prefeituras_status"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_status === false}
                  onChange={handleChangeRadio('prefeituras_status')}
                  name="prefeituras_status"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_criar === true}
                  onChange={handleChangeRadio('prefeituras_criar')}
                  name="prefeituras_criar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_criar === false}
                  onChange={handleChangeRadio('prefeituras_criar')}
                  name="prefeituras_criar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_editar === true}
                  onChange={handleChangeRadio('prefeituras_editar')}
                  name="prefeituras_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_editar === false}
                  onChange={handleChangeRadio('prefeituras_editar')}
                  name="prefeituras_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Deletar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_deletar === true}
                  onChange={handleChangeRadio('prefeituras_deletar')}
                  name="prefeituras_deletar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.prefeituras_deletar === false}
                  onChange={handleChangeRadio('prefeituras_deletar')}
                  name="prefeituras_deletar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Vereadores</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ver Vereadores</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_ver === true}
                  onChange={handleChangeRadio('vereador_ver')}
                  name="vereador_ver"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_ver === false}
                  onChange={handleChangeRadio('vereador_ver')}
                  name="vereador_ver"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ativiar/Desativar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_status === true}
                  onChange={handleChangeRadio('vereador_status')}
                  name="vereador_status"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_status === false}
                  onChange={handleChangeRadio('vereador_status')}
                  name="vereador_status"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_criar === true}
                  onChange={handleChangeRadio('vereador_criar')}
                  name="vereador_criar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_criar === false}
                  onChange={handleChangeRadio('vereador_criar')}
                  name="vereador_criar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_editar === true}
                  onChange={handleChangeRadio('vereador_editar')}
                  name="vereador_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_editar === false}
                  onChange={handleChangeRadio('vereador_editar')}
                  name="vereador_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Deletar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_deletar === true}
                  onChange={handleChangeRadio('vereador_deletar')}
                  name="vereador_deletar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.vereador_deletar === false}
                  onChange={handleChangeRadio('vereador_deletar')}
                  name="vereador_deletar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperFull}>Atividades</Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ver Atividades</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_ver === true}
                  onChange={handleChangeRadio('atividades_ver')}
                  name="atividades_ver"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_ver === false}
                  onChange={handleChangeRadio('atividades_ver')}
                  name="atividades_ver"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Ativiar/Desativar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_status === true}
                  onChange={handleChangeRadio('atividades_status')}
                  name="atividades_status"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_status === false}
                  onChange={handleChangeRadio('atividades_status')}
                  name="atividades_status"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Criar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_criar === true}
                  onChange={handleChangeRadio('atividades_criar')}
                  name="atividades_criar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_criar === false}
                  onChange={handleChangeRadio('atividades_criar')}
                  name="atividades_criar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Editar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_editar === true}
                  onChange={handleChangeRadio('atividades_editar')}
                  name="atividades_editar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_editar === false}
                  onChange={handleChangeRadio('atividades_editar')}
                  name="atividades_editar"
                  value="false"
                />
                Não
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>Deletar</Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_deletar === true}
                  onChange={handleChangeRadio('atividades_deletar')}
                  name="atividades_deletar"
                  value="true"
                />
                Sim
              </div>
            </Grid>
            <Grid item xs={6} sm={3}>
              <div className={classes.paperRadio}>
                <Radio
                  checked={values.atividades_deletar === false}
                  onChange={handleChangeRadio('atividades_deletar')}
                  name="atividades_deletar"
                  value="false"
                />
                Não
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </div>
  );
}
