import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content, Background } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Background>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Background>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
