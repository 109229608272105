/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import history from '~/services/history';

import Secretarias from './Secretarias';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
  center: {
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function EditarVereador(props) {
  const classes = useStyles();
  const labelRef = React.useRef(null);
  const labelWidth = 85;
  const [loading, setLoading] = React.useState(false);
  const [ufs, setUfs] = React.useState([]);
  const [localidades, setLocalidades] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [right, setRight] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [rows, setRows] = React.useState([]);
  const [mount, setMount] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [values, setValues] = React.useState({
    id_uf: '',
    id_localidade: '',
    nome: '',
    endereco: '',
    bairro: '',
    cep: '',
    cpf_cnpj: '',
    ie: '',
    telefone: '',
    observacao: '',
    tipo: 'Vereador',
  });
  const [valuesMap, setValuesMap] = React.useState({
    center: {
      lat: -12.2935,
      lng: -50.608584,
    },
    zoom: 5,
  });

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  function getRedirect() {
    api.get(`redirects/${props.match.params.id}`).then(response => {
      setRows(response.data);
    });
  }

  function getActivities() {
    api.get(`activities/${props.match.params.id}`).then(response => {
      const listRight = response.data.right.map(a => {
        return {
          id: a.id,
          nome: a.nome,
        };
      });
      setRight(listRight);
      const listLeft = response.data.left.map(a => {
        return {
          id: a.id,
          nome: a.nome,
        };
      });
      setLeft(listLeft);
    });
  }

  function getMap() {
    // eslint-disable-next-line react/prop-types
    api.get(`group-map/${props.match.params.id}`).then(response => {
      const listMap = {
        center: {
          lat: parseFloat(response.data.lat),
          lng: parseFloat(response.data.long),
        },
        // eslint-disable-next-line radix
        zoom: parseInt(response.data.zoom),
      };

      setValuesMap(listMap);
      // console.log(listMap)
    });
  }

  function handleSaveAtividades() {
    setLoading(true);
    if (right.length === 0) {
      setLoading(false);
      toast.error('Nenhuma atividade selecionada', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    } else {
      try {
        setLoading(false);
        api
          .post('activities-groups', {
            // eslint-disable-next-line react/prop-types
            id_grupo: props.match.params.id,
            atividades: right,
          })
          .then(response => {
            toast.info(response.data.ok, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          });
      } catch (err) {
        setLoading(false);
        toast.error('Erro ao salvar atividades', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  }
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const customList = items => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.nome}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  function getUfs() {
    api.get('uf').then(response => {
      const listUfs = response.data.map(uf => {
        return {
          id: uf.id,
          sigla: uf.sigla,
        };
      });
      setUfs(listUfs);
    });
  }

  function getLocalidades(id) {
    api.get(`locale/${id}`).then(resp => {
      const listLocales = resp.data.map(l => {
        return {
          id: l.id,
          id_uf: l.id_uf,
          nome: l.nome,
        };
      });
      setLocalidades(listLocales);
    });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });

    if (name === 'id_uf') {
      values.id_localidade = '';
      getLocalidades(event.target.value);
    }
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function handleSaveMap() {
    setLoading(true);
    try {
      setLoading(false);
      api
        .post('group-map', {
          // eslint-disable-next-line react/prop-types
          id_grupo: props.match.params.id,
          lat: valuesMap.center.lat,
          long: valuesMap.center.lng,
          zoom: valuesMap.zoom,
        })
        .then(response => {
          toast.info(response.data.ok, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });
    } catch (err) {
      setLoading(false);
      toast.error('Erro ao salvar mapa', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  const handleChangeMap = name => event => {
    if (name === 'zoom') {
      setValuesMap({
        center: {
          lat: valuesMap.center.lat,
          lng: valuesMap.center.lng,
        },
        // eslint-disable-next-line radix
        zoom: parseInt(event.target.value),
      });
    }
    if (name === 'lat') {
      setValuesMap({
        center: {
          lat: parseFloat(event.target.value),
          lng: valuesMap.center.lng,
        },
        zoom: valuesMap.zoom,
      });
    }
    if (name === 'lng') {
      setValuesMap({
        center: {
          lat: valuesMap.center.lng,
          lng: parseFloat(event.target.value),
        },
        zoom: valuesMap.zoom,
      });
    }
  };

  React.useEffect(() => {
    if (!mount) {
      api.get('functions').then(response => {
        setEdit(response.data.vereador_editar);
      });

      setMount(true);
    }

    async function getVereador() {
      const response = await api.get(`assemblymans/${props.match.params.id}`);
      const user = {
        id: response.data.id,
        nome: response.data.nome,
        endereco: response.data.endereco,
        bairro: response.data.bairro,
        cep: response.data.cep,
        id_uf: response.data.id_uf,
        id_localidade: response.data.id_localidade,
        cpf_cnpj: response.data.cpf_cnpj,
        ie: response.data.ie,
        telefone: response.data.telefone,
        observacao: response.data.observacao ? response.data.observacao : '',
      };
      setValues(user);
    }
    // eslint-disable-next-line react/prop-types
    if (values.nome === '' && props.match.params.id > 1) {
      getVereador();
    }
    if (ufs.length === 0) {
      getUfs();
    }

    if (localidades.length === 0 && values.id_uf !== '') {
      getLocalidades(values.id_uf);
    }
  }, [ufs, props, localidades, values, tab, right, mount]);

  function validation() {
    if (
      values.id_uf === '' ||
      values.id_localidade === '' ||
      values.cpf_cnpj === '' ||
      values.nome === ''
    ) {
      return false;
    }
    return true;
  }

  function handleSave() {
    setLoading(true);

    const valid = validation();

    if (valid) {
      try {
        api.put('assemblymans', { values }).then(response => {
          if (response.data.error) {
            toast.error(response.data.error, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }

          if (response.data.nome) {
            toast.info(`${response.data.nome} criada com sucesso`, {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
            history.push('/vereadores');
          }
        });
      } catch (err) {
        toast.error('Erro ao salvar vereador', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    } else {
      setLoading(false);
      setError(true);
      toast.error('Campos obrigatorios devem ser preenchidos', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  function handleDelete(id) {
    try {
      api.delete(`redirects/${id}`).then(response => {
        if (response.data.error) {
          toast.error(response.data.error, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        }

        if (response.data.ok) {
          getRedirect();
          toast.info(response.data.ok, {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        }
      });
    } catch (err) {
      toast.error('Erro deletar redirecionamento', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  return (
    <>
      {edit ? (
        <>
          <Paper className={classes.root}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Editar Vereador" />
              <Tab label="Editar Atividades" onClick={() => getActivities()} />
              <Tab label="Editar Secretarias" />
              <Tab label="Editar Mapa" onClick={() => getMap()} />
              <Tab
                label="Configurar Redirecionamento"
                onClick={() => getRedirect()}
              />
            </Tabs>
          </Paper>
          {tab === 0 && (
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar>
                  <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                      Editar Vereador
                    </Typography>
                  </div>
                  <div className={classes.spacer} />
                  {loading === true ? (
                    <div className={classes.actions}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <>
                      <div className={classes.actions}>
                        <Link to="/vereadores">
                          <Tooltip title="Cancelar">
                            <IconButton aria-label="Cancelar">
                              <Close color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                      <div className={classes.actions}>
                        <Tooltip title="Salvar Usuário">
                          <IconButton
                            aria-label="Salvar Usuário"
                            onClick={() => handleSave()}
                          >
                            <Save color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </Toolbar>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        required
                      >
                        <InputLabel ref={labelRef} htmlFor="nome">
                          Nome
                        </InputLabel>
                        <OutlinedInput
                          id="nome"
                          name="nome"
                          error={error && values.nome === ''}
                          value={values.nome}
                          onChange={handleChange('nome')}
                          labelWidth={labelWidth}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel ref={labelRef} htmlFor="endereco">
                          Endereço
                        </InputLabel>
                        <OutlinedInput
                          id="endereco"
                          // error={errorNome}
                          value={values.endereco}
                          onChange={handleChange('endereco')}
                          labelWidth={labelWidth}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel ref={labelRef} htmlFor="bairro">
                          Bairro
                        </InputLabel>
                        <OutlinedInput
                          id="bairro"
                          // error={errorNome}
                          value={values.bairro}
                          onChange={handleChange('bairro')}
                          labelWidth={labelWidth}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel ref={labelRef} htmlFor="cep">
                          CEP
                        </InputLabel>
                        <OutlinedInput
                          id="cep"
                          // error={errorNome}
                          value={values.cep}
                          onChange={handleChange('cep')}
                          labelWidth={labelWidth}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <TextField
                        id="id_uf"
                        select
                        // error={errorUf}
                        label="UF"
                        className={classes.formControl}
                        value={values.id_uf}
                        onChange={handleChange('id_uf')}
                        error={error && values.id_uf === ''}
                        required
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <option key="0" value="">
                          {''}
                        </option>
                        {ufs.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.sigla}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        id="id_localidade"
                        select
                        required
                        error={error && values.id_localidade === ''}
                        disabled={values.id_uf === ''}
                        label="Cidade"
                        className={classes.formControl}
                        value={values.id_localidade}
                        onChange={handleChange('id_localidade')}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        <option key="0" value="">
                          {' '}
                        </option>
                        {localidades.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.nome}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        required
                      >
                        <InputLabel ref={labelRef} htmlFor="cpf_cnpj">
                          CNPJ
                        </InputLabel>
                        <OutlinedInput
                          id="cpf_cnpj"
                          required
                          error={error && values.cpf_cnpj === ''}
                          value={values.cpf_cnpj}
                          onChange={handleChange('cpf_cnpj')}
                          labelWidth={labelWidth}
                          // inputComponent={MaskCpf}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel ref={labelRef} htmlFor="ie">
                          Inscrição Estadual
                        </InputLabel>
                        <OutlinedInput
                          id="ie"
                          value={values.ie}
                          onChange={handleChange('ie')}
                          labelWidth={labelWidth}
                          // inputComponent={MaskCpf}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel ref={labelRef} htmlFor="telefone">
                          Telefone
                        </InputLabel>
                        <OutlinedInput
                          id="telefone"
                          value={values.telefone}
                          onChange={handleChange('telefone')}
                          labelWidth={labelWidth}
                          // inputComponent={MaskTelefone}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="observação"
                        label="Observação"
                        className={classes.formControl}
                        multiline
                        rowsMax="4"
                        value={values.observacao}
                        onChange={handleChange('observacao')}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </div>
          )}
          {tab === 1 && (
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar>
                  <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                      Editar Atividades
                    </Typography>
                  </div>
                  <div className={classes.spacer} />
                  {loading === true ? (
                    <div className={classes.actions}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <>
                      <div className={classes.actions}>
                        <Link to="/vereadores">
                          <Tooltip title="Cancelar">
                            <IconButton aria-label="Cancelar">
                              <Close color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                      <div className={classes.actions}>
                        <Tooltip title="Salvar Atividades">
                          <IconButton
                            aria-label="Salvar Atividades"
                            onClick={() => handleSaveAtividades()}
                          >
                            <Save color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </Toolbar>

                <Grid
                  container
                  spacing={2}
                  justify="center"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item>{customList(left)}</Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                      >
                        ≫
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                      >
                        ≪
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList(right)}</Grid>
                </Grid>
              </Paper>
            </div>
          )}
          {tab === 2 && <Secretarias id={props.match.params.id} />}
          {tab === 3 && (
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar>
                  <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                      Editar Mapa
                    </Typography>
                  </div>
                  <div className={classes.spacer} />
                  {loading === true ? (
                    <div className={classes.actions}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <>
                      <div className={classes.actions}>
                        <Link to="/vereadores">
                          <Tooltip title="Cancelar">
                            <IconButton aria-label="Cancelar">
                              <Close color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                      <div className={classes.actions}>
                        <Tooltip title="Salvar Mapa">
                          <IconButton
                            aria-label="Salvar Mapa"
                            onClick={() => handleSaveMap()}
                          >
                            <Save color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </Toolbar>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <div className={classes.center}>
                    <TextField
                      id="outlined-name"
                      label="Latitude"
                      className={classes.textField}
                      value={valuesMap.center.lat}
                      onChange={handleChangeMap('lat')}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-name"
                      label="Longitude"
                      className={classes.textField}
                      value={valuesMap.center.lng}
                      onChange={handleChangeMap('lng')}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-name"
                      label="Zoom"
                      type="number"
                      className={classes.textField}
                      value={valuesMap.zoom}
                      onChange={handleChangeMap('zoom')}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>
                </form>
                <div style={{ height: '80vh', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: 'AIzaSyDSp_yy3zd-yk5M1MUO67MXydvhkL8O2P8',
                    }}
                    center={valuesMap.center}
                    zoom={valuesMap.zoom}
                  />
                </div>
              </Paper>
            </div>
          )}
          {tab === 4 && (
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar>
                  <div className={classes.title}>
                    <Typography variant="h6" id="tableTitle">
                      Redirecionamentos
                    </Typography>
                  </div>
                  <div className={classes.spacer} />
                  {loading === true ? (
                    <div className={classes.actions}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <>
                      <div className={classes.actions}>
                        <Link to="/vereadores">
                          <Tooltip title="Cancelar">
                            <IconButton aria-label="Cancelar">
                              <Close color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                      <div className={classes.actions}>
                        <Link
                          to={`/vereadores/redirecionamentos/${props.match.params.id}`}
                        >
                          <Tooltip title="Criar Redirecionamento">
                            <IconButton aria-label="Criar Redirecionamento">
                              <Add color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                    </>
                  )}
                </Toolbar>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">Posto</TableCell>
                      <TableCell align="center">Secretaria</TableCell>
                      <TableCell align="center">Editar</TableCell>
                      <TableCell align="center">Deletar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.atividade.nome}
                        </TableCell>
                        <TableCell align="center">{row.posto.nome}</TableCell>
                        <TableCell align="center">
                          {row.secretaria.nome}
                        </TableCell>
                        <TableCell align="center">
                          <Edit color="primary" />
                        </TableCell>
                        <TableCell align="center">
                          <Delete
                            color="primary"
                            onClick={() => handleDelete(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          )}
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </>
  );
}
