/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
  warning: {
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
}));

function MaskCelular(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskTelefone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskCpf(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function MaskNascimento(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

MaskCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskCpf.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
MaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function CriarUsuario(props) {
  const classes = useStyles();
  const { id } = props.match.params;
  const labelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    id: '',
    id_localidade: '',
    username: '',
    password: '',
    ativo: true,
    nome: '',
    email: '',
    telefone: ' ',
    celular: ' ',
    nascimento: ' ',
    cpf: ' ',
    id_encarregado: '',
    email_de_aviso: false,
    controle_de_ponto: false,
    assessor: false,
    id_uf: '',
    encarregado: false,
    id_perfil: '',
    id_grupo: '',
    observacao: '',
  });
  const [ufs, setUfs] = React.useState([]);
  const [localidades, setLocalidades] = React.useState([]);
  const [perfis, setPerfis] = React.useState([]);
  const [grupos, setGrupos] = React.useState([]);
  const [supervisor, setSupervisor] = React.useState([]);
  const [errorNome, setErrorNome] = React.useState(false);
  const [errorUf, setErrorUf] = React.useState(false);
  const [errorLocalidade, setErrorLocalidade] = React.useState(false);
  const [errorUsername, setErrorUsername] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [errorPerfil, setErrorPerfil] = React.useState(false);
  const [errorGrupo, setErrorGrupo] = React.useState(false);
  const [errorNascimento, setErrorNascimento] = React.useState(false);
  const [mount, setMount] = React.useState(false);
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    if (!mount) {
      setLabelWidth(85);

      api.get('functions').then(response => {
        setEdit(response.data.usuarios_editar);
      });

      api
        .get(`users/${id}`)
        .then(response => {
          const user = {
            id: response.data.id,
            id_localidade: response.data.id_localidade,
            username: response.data.username,
            password: '00000000',
            ativo: response.data.ativo,
            id_grupo: response.data.id_grupo,
            nome: response.data.nome,
            email: response.data.email,
            telefone: response.data.telefone ? response.data.telefone : ' ',
            celular: response.data.celular ? response.data.celular : ' ',
            nascimento: response.data.nascimento
              ? response.data.nascimento
              : ' ',
            cpf: response.data.cpf ? response.data.cpf : ' ',
            id_encarregado:
              response.data.id_encarregado === null
                ? ''
                : response.data.id_encarregado,
            email_de_aviso: response.data.email_de_aviso,
            controle_de_ponto: response.data.controle_de_ponto,
            id_uf: response.data.id_uf,
            encarregado: response.data.encarregado,
            assessor: response.data.assessor,
            id_perfil: response.data.id_perfil,
            observacao: response.data.observacao,
          };
          setValues(user);
        })
        .catch(() => {
          toast.error('Erro ao buscar usuários', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        });

      api.get('profile?active=true').then(response => {
        const listProfiles = response.data.map(p => {
          return {
            id: p.id,
            nome: p.nome,
          };
        });
        setPerfis(listProfiles);
      });

      api.get('uf').then(response => {
        const listUfs = response.data.map(uf => {
          return {
            id: uf.id,
            sigla: uf.sigla,
          };
        });
        setUfs(listUfs);
      });

      setMount(true);
    }

    if (values.nome !== '') {
      setErrorNome(false);
    }
    if (values.id_uf !== '') {
      setErrorUf(false);
    }
    if (values.id_localidade !== '') {
      setErrorLocalidade(false);
    }
    if (values.username !== '') {
      setErrorUsername(false);
    }
    if (values.password !== '') {
      setErrorPassword(false);
    }
    if (values.id_perfil !== '') {
      setErrorPerfil(false);
    }
    if (values.id_grupo !== '') {
      setErrorGrupo(false);
    }
    if (values.id_uf === '') {
      values.id_localidade = '';
      setGrupos([]);
    }
    // eslint-disable-next-line radix
    if (values.nascimento !== '' && parseInt(values.nascimento[(0, 1)]) <= 31) {
      setErrorNascimento(false);
    }

    async function loadLocale() {
      const resp = await api.get(`locale/${values.id_uf}`);
      const listLocales = resp.data.map(l => {
        return {
          id: l.id,
          id_uf: l.id_uf,
          nome: l.nome,
        };
      });
      setLocalidades(listLocales);
    }

    if (values.id_uf !== '') {
      loadLocale();
    }

    async function loadGroup() {
      const resp = await api.get(`group/${values.id_localidade}`);
      const listGroup = resp.data.map(g => {
        return {
          id: g.id,
          nome: g.nome,
        };
      });
      setGrupos(listGroup);
    }
    if (values.id_localidade !== '') {
      loadGroup();
    }

    if (values.id_grupo !== '') {
      api
        .get(`supervisor/${values.id_localidade}?id_grupo=${values.id_grupo}`)
        .then(response => {
          const listSupervisor = response.data.map(s => {
            return {
              id: s.id,
              nome: s.nome,
            };
          });
          setSupervisor(listSupervisor);
        });
    }
  }, [values, ufs, perfis, props, localidades.length, mount, id]);

  const handleChangeCheck = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function validateUser() {
    if (values.nome === '') {
      return false;
    }
    if (values.uf === '') {
      return false;
    }
    if (values.id_localidade === '') {
      return false;
    }
    if (values.username === '') {
      return false;
    }
    if (values.password === '') {
      return false;
    }
    if (values.id_perfil === '') {
      return false;
    }
    if (values.id_grupo === '') {
      return false;
    }
    if (values.nascimento === '' || errorNascimento) {
      return false;
    }
    return true;
  }

  async function saveUser() {
    if (values.cpf === ' ') {
      values.cpf = null;
    }
    if (values.nascimento === ' ') {
      values.nascimento = null;
    }
    if (values.telefone === ' ') {
      values.telefone = null;
    }
    if (values.celular === ' ') {
      values.celular = null;
    }
    if (values.id_encarregado === '') {
      values.id_encarregado = null;
    }
    if (values.password === '00000000') {
      values.password = null;
    }
    if (values.nascimento === '') {
      setErrorNascimento(true);
    } else {
      setErrorNascimento(false);
    }
    // eslint-disable-next-line radix
    if (parseInt(values.nascimento[(0, 1)]) <= 31) {
      setErrorNascimento(false);
    } else {
      setErrorNascimento(true);
    }
    try {
      const response = await api.put('users', { values });
      if (response.data.error) {
        toast.error(response.data.error, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }

      if (response.data.nome) {
        toast.info(`Usuario ${response.data.nome} atualizado com sucesso`, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        history.push('/usuarios');
      }
    } catch (err) {
      toast.error('Erro ao salvar usuário', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
    setLoading(false);
  }

  async function handleSaveUser() {
    setLoading(true);

    let validation = await validateUser();

    if (values.nome === '') {
      setErrorNome(true);
    } else {
      setErrorNome(false);
    }
    if (values.id_uf === '') {
      setErrorUf(true);
    } else {
      setErrorUf(false);
    }
    if (values.id_localidade === '') {
      setErrorLocalidade(true);
    } else {
      setErrorLocalidade(false);
    }
    if (values.username === '') {
      setErrorUsername(true);
    } else {
      setErrorUsername(false);
    }
    if (values.password === '') {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
    if (values.id_perfil === '') {
      setErrorPerfil(true);
    } else {
      setErrorPerfil(false);
    }
    if (values.id_grupo === '') {
      setErrorGrupo(true);
    } else {
      setErrorGrupo(false);
    }
    // eslint-disable-next-line radix
    if (parseInt(values.nascimento[(0, 1)]) <= 31) {
      setErrorNascimento(false);
    } else {
      setErrorNascimento(true);
      validation = false;
    }

    if (validation === true) {
      saveUser();
    } else {
      setLoading(false);
      toast.error('Os campos em vermelho devem ser preenchidos', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }

  return (
    <div className={classes.root}>
      {edit ? (
        <>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Editar Usuário
              </Typography>
            </div>
            <div className={classes.spacer} />
            {loading === true ? (
              <div className={classes.actions}>
                <CircularProgress className={classes.progress} />
              </div>
            ) : (
              <>
                <div className={classes.actions}>
                  <Link to="/usuarios">
                    <Tooltip title="Cancelar">
                      <IconButton aria-label="Cancelar">
                        <Close color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </div>
                <div className={classes.actions}>
                  <Tooltip title="Salvar Usuário">
                    <IconButton
                      aria-label="Salvar Usuário"
                      onClick={() => handleSaveUser()}
                    >
                      <Save color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )}
          </Toolbar>
          <Paper className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="nome">
                      Nome
                    </InputLabel>
                    <OutlinedInput
                      id="nome"
                      error={errorNome}
                      value={values.nome}
                      onChange={handleChange('nome')}
                      labelWidth={labelWidth}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="username">
                      Usuário
                    </InputLabel>
                    <OutlinedInput
                      id="username"
                      error={errorUsername}
                      value={values.username}
                      onChange={handleChange('username')}
                      labelWidth={labelWidth}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="password">
                      Senha
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      error={errorPassword}
                      type="password"
                      value={values.password}
                      onChange={handleChange('password')}
                      labelWidth={labelWidth}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="email">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id="email"
                      value={values.email}
                      onChange={handleChange('email')}
                      labelWidth={labelWidth}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="cpf">
                      CPF
                    </InputLabel>
                    <OutlinedInput
                      id="cpf"
                      value={values.cpf}
                      onChange={handleChange('cpf')}
                      labelWidth={labelWidth}
                      inputComponent={MaskCpf}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="nascimento">
                      Nascimento
                    </InputLabel>
                    <OutlinedInput
                      id="nascimento"
                      value={values.nascimento}
                      onChange={handleChange('nascimento')}
                      labelWidth={labelWidth}
                      inputComponent={MaskNascimento}
                      error={errorNascimento}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="telefone">
                      Telefone
                    </InputLabel>
                    <OutlinedInput
                      id="telefone"
                      value={values.telefone}
                      onChange={handleChange('telefone')}
                      labelWidth={labelWidth}
                      inputComponent={MaskTelefone}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel ref={labelRef} htmlFor="celular">
                      Celular
                    </InputLabel>
                    <OutlinedInput
                      id="celular"
                      value={values.celular}
                      onChange={handleChange('celular')}
                      labelWidth={labelWidth}
                      inputComponent={MaskCelular}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <TextField
                    id="id_uf"
                    select
                    error={errorUf}
                    label="UF"
                    className={classes.formControl}
                    value={values.id_uf}
                    onChange={handleChange('id_uf')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <option key="0" value="">
                      {''}
                    </option>
                    {ufs.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.sigla}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="id_localidade"
                    select
                    error={errorLocalidade}
                    disabled={values.id_uf === ''}
                    label="Cidade"
                    className={classes.formControl}
                    value={values.id_localidade}
                    onChange={handleChange('id_localidade')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {localidades.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="id_grupo"
                    select
                    error={errorGrupo}
                    label="Grupo"
                    className={classes.formControl}
                    value={values.id_grupo}
                    onChange={handleChange('id_grupo')}
                    disabled={values.id_localidade === ''}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <option key="0" value="">
                      {''}
                    </option>
                    {grupos.map(grupo => (
                      <option key={grupo.id} value={grupo.id}>
                        {grupo.nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="id_perfil"
                    select
                    error={errorPerfil}
                    label="Perfil"
                    className={classes.formControl}
                    value={values.id_perfil}
                    onChange={handleChange('id_perfil')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <option key="0" value="">
                      {''}
                    </option>
                    {perfis.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="id_encarregado"
                    select
                    disabled={values.id_localidade === ''}
                    label="Encarregado"
                    className={classes.formControl}
                    value={values.id_encarregado}
                    onChange={handleChange('id_encarregado')}
                    SelectProps={{
                      native: true,
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    <option key="0" value="null">
                      {''}
                    </option>
                    {supervisor.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    label="É encarregado?"
                    control={
                      <Checkbox
                        checked={values.encarregado}
                        onChange={handleChangeCheck('encarregado')}
                        value={values.encarregado}
                        color="primary"
                      />
                    }
                  />
                  <FormControlLabel
                    label="É assessor?"
                    control={
                      <Checkbox
                        checked={values.assessor}
                        onChange={handleChangeCheck('assessor')}
                        value={values.controle_de_ponto}
                        color="primary"
                      />
                    }
                  />
                  <FormControlLabel
                    label="Email de aviso?"
                    control={
                      <Checkbox
                        checked={values.email_de_aviso}
                        onChange={handleChangeCheck('email_de_aviso')}
                        value={values.email_de_aviso}
                        color="primary"
                      />
                    }
                  />
                  <FormControlLabel
                    label="Ativo?"
                    control={
                      <Checkbox
                        checked={values.ativo}
                        onChange={handleChangeCheck('ativo')}
                        value={values.ativo}
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="observação"
                    label="Observação"
                    className={classes.formControl}
                    multiline
                    rowsMax="4"
                    value={values.observacao}
                    onChange={handleChange('observacao')}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </>
      ) : (
        <div className={classes.warning}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </div>
  );
}
