/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable radix */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
  },
}));

export default function Redirecionamentos(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [activityGroup, setActivityGroup] = React.useState([]);
  const [workstations, setWorkstations] = React.useState([]);
  const [secretariats, seteSecretariats] = React.useState([]);
  const [values, setValues] = React.useState({
    id_atividade: 0,
    id_grupo: parseInt(props.match.params.id),
    id_posto: 0,
    id_secretaria: 0,
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleSave() {
    setLoading(false);
    api.post('redirects', values).then(response => {
      if (response.data.error) {
        toast.error(response.data.error, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }

      if (response.data.ok) {
        toast.info(response.data.ok, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        history.push(`/vereadores/editar/${props.match.params.id}`);
      }
    });
  }

  useEffect(() => {
    if (activityGroup.length === 0) {
      api.get(`activities-groups/${props.match.params.id}`).then(response => {
        if (response.data.length !== 0) {
          setActivityGroup(response.data);
        }
      });
    }

    if (workstations.length === 0) {
      api.get('workstations').then(response => {
        setWorkstations(response.data);
      });
    }

    if (secretariats.length === 0) {
      api.get(`secretariats-groups/${props.match.params.id}`).then(response => {
        seteSecretariats(response.data);
        // console.log(response.data);
      });
    }
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Criar Redirecionamentos
            </Typography>
          </div>
          <div className={classes.spacer} />
          {loading === true ? (
            <div className={classes.actions}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <>
              <div className={classes.actions}>
                <Link to={`/vereadores/editar/${props.match.params.id}`}>
                  <Tooltip title="Cancelar">
                    <IconButton aria-label="Cancelar">
                      <Close color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </div>
              <div className={classes.actions}>
                <Tooltip title="Salvar Redirecionamento">
                  <IconButton
                    aria-label="Salvar Redirecionamento"
                    onClick={() => handleSave()}
                  >
                    <Save color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
        </Toolbar>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Atividade</TableCell>
                <TableCell align="center">Posto</TableCell>
                <TableCell align="center">Secretaria</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="1">
                <TableCell component="th" scope="row">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">Atividade</InputLabel>
                    <Select
                      value={values.id_atividade}
                      onChange={handleChange('id_atividade')}
                    >
                      {activityGroup.map(activity => (
                        <MenuItem
                          key={activity.atividade.id}
                          value={activity.atividade.id}
                        >
                          {activity.atividade.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell component="th" scope="row">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">Posto</InputLabel>
                    <Select
                      value={values.id_posto}
                      onChange={handleChange('id_posto')}
                    >
                      {workstations.map(worstation => (
                        <MenuItem key={worstation.id} value={worstation.id}>
                          {worstation.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell component="th" scope="row">
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-simple">Secretaria</InputLabel>
                    <Select
                      value={values.id_secretaria}
                      onChange={handleChange('id_secretaria')}
                    >
                      {secretariats.map(secretariat => (
                        <MenuItem
                          key={secretariat.secretaria.id}
                          value={secretariat.id}
                        >
                          {secretariat.secretaria.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Paper>
    </div>
  );
}
