import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';
import Perfis from '../pages/Perfis';
import PerfisEditar from '../pages/Perfis/Funcionalidades';
import PerfisCriar from '../pages/Perfis/Criar';
import Chamados from '../pages/Chamados';
import ChamadosEditar from '../pages/Chamados/Editar';
import ChamadosCriar from '../pages/Chamados/Criar';
import Usuarios from '../pages/Usuarios';
import UsuariosCriar from '../pages/Usuarios/Criar';
import UsuariosEditar from '../pages/Usuarios/Editar';
import Prefeituras from '../pages/Prefeituras';
import PrefeiturasCriar from '../pages/Prefeituras/Criar';
import PrefeiturasEditar from '../pages/Prefeituras/Editar';
import PrefeiturasRedirecionamentos from '../pages/Prefeituras/Redirecionamentos';
import Vereadores from '../pages/Vereadores';
import VereadoresCriar from '../pages/Vereadores/Criar';
import VereadoresEditar from '../pages/Vereadores/Editar';
import VereadoresRedirecionamentos from '../pages/Vereadores/Redirecionamentos';
import Atividades from '../pages/Atividades';
import AtividadesCriar from '../pages/Atividades/Criar';
import AtividadesEditar from '../pages/Atividades/Editar';
import PerfilUsuario from '../pages/PerfilUsuario';
import Mapa from '../pages/Mapa';

export default function Routes() {
  return (
    // <BrowserRouter basename="/painel">
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/perfis" exact component={Perfis} isPrivate />
      <Route path="/perfis/criar" exact component={PerfisCriar} isPrivate />
      <Route
        path="/perfis/editar/:id"
        exact
        component={PerfisEditar}
        isPrivate
      />
      <Route path="/solicitacoes" exact component={Chamados} isPrivate />
      <Route
        path="/solicitacoes/visualizar/:id"
        exact
        component={ChamadosEditar}
        isPrivate
      />
      <Route
        path="/solicitacoes/editar/:id"
        exact
        component={ChamadosEditar}
        isPrivate
      />
      <Route
        path="/solicitacoes/criar"
        exact
        component={ChamadosCriar}
        isPrivate
      />
      <Route path="/usuarios" exact component={Usuarios} isPrivate />
      <Route path="/usuarios/criar" component={UsuariosCriar} isPrivate />
      <Route path="/usuarios/editar/:id" component={UsuariosEditar} isPrivate />
      <Route path="/prefeituras" exact component={Prefeituras} isPrivate />
      <Route path="/prefeituras/criar" component={PrefeiturasCriar} isPrivate />
      <Route
        path="/prefeituras/redirecionamentos/:id"
        component={PrefeiturasRedirecionamentos}
        isPrivate
      />
      <Route
        path="/prefeituras/editar/:id"
        component={PrefeiturasEditar}
        isPrivate
      />
      <Route path="/vereadores" exact component={Vereadores} isPrivate />
      <Route path="/vereadores/criar" component={VereadoresCriar} isPrivate />
      <Route
        path="/vereadores/editar/:id"
        component={VereadoresEditar}
        isPrivate
      />
      <Route
        path="/vereadores/redirecionamentos/:id"
        component={VereadoresRedirecionamentos}
        isPrivate
      />
      <Route path="/atividades" exact component={Atividades} isPrivate />
      <Route path="/atividades/criar" component={AtividadesCriar} isPrivate />
      <Route
        path="/atividades/editar/:id"
        component={AtividadesEditar}
        isPrivate
      />
      <Route path="/mapa" component={Mapa} isPrivate />
      <Route path="/perfil" component={PerfilUsuario} isPrivate />
    </Switch>
    // </BrowserRouter>
  );
}
