import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from 'material-ui/Paper';
import FlatButton from 'material-ui/FlatButton';
import { grey500, white } from 'material-ui/styles/colors';
import Button from '@material-ui/core/Button';
import Help from 'material-ui/svg-icons/action/help';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import api from '~/services/api';

import ThemeDefault from '../_layouts/theme-default';

import { signInRequest } from '~/store/modules/auth/actions';
import logo from '~/assets/logo.png';

const schema = Yup.object().shape({
  username: Yup.string().required('O usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

function MaskNascimento(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

MaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    // margin: 'auto',
    width: 350,
  },
}));

const LoginPage = () => {
  const styles = {
    loginContainer: {
      minWidth: 320,
      maxWidth: 400,
      height: 'auto',
      position: 'absolute',
      top: '20%',
      left: 0,
      right: 0,
      margin: 'auto',
    },
    paper: {
      padding: 20,
      overflow: 'auto',
    },
    buttonsDiv: {
      textAlign: 'center',
      padding: 10,
    },
    flatButton: {
      color: grey500,
    },
    checkRemember: {
      style: {
        float: 'left',
        maxWidth: 180,
        paddingTop: 5,
      },
      labelStyle: {
        color: grey500,
      },
      iconStyle: {
        color: grey500,
        borderColor: grey500,
        fill: grey500,
      },
    },
    loginBtn: {
      float: 'right',
    },
    btn: {
      background: '#4f81e9',
      color: white,
      padding: 7,
      borderRadius: 2,
      margin: 2,
      fontSize: 13,
    },
    btnFacebook: {
      background: '#4f81e9',
    },
    btnGoogle: {
      background: '#e14441',
    },
    btnSpan: {
      marginLeft: 5,
    },
    label: {
      top: 0,
      left: 0,
      right: 0,
      color: '#616161',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      fontSize: '1rem',
      cursor: 'text',
      transition: '.2s ease-in-out',
      boxSizing: 'border-box',
    },
    lente: {
      padding: '10px',
      color: '#3533ee',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 10,
      border: 'none',
      background: 'transparent',
      outline: 'none',
    },
    line: {
      position: 'relative',
      width: '100%',
    },
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const [open, setOpen] = React.useState({ open: false });
  const [error, setError] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [visibility, setVisibility] = React.useState(false);
  const [values, setValues] = React.useState({
    username: '',
    email: '',
    nascimento: '01012020',
  });

  function handleSubmit({ username, password }) {
    dispatch(signInRequest(username, password));
  }

  const handleClickOpen = () => {
    setOpen({ ...open, open: true });
  };

  const handleClose = () => {
    setError(false);
    setWarning(false);
    setFailed(false);
    setValues({
      username: '',
      email: '',
      nascimento: '',
    });
    setOpen({ ...open, open: false });
  };

  const handleRecovery = () => {
    if (
      values.username !== '' &&
      values.email !== '' &&
      values.nascimento !== ''
    ) {
      api.post('recovery', values).then(response => {
        setProgress(false);
        if (response.data.error) {
          setFailed(true);
        }
        setWarning(true);
      });
    } else {
      setError(true);
    }
  };

  const handleChange = name => event => {
    setError(false);
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <MuiThemeProvider muiTheme={ThemeDefault}>
      <div>
        <div style={styles.loginContainer}>
          <Paper style={styles.paper}>
            <img src={logo} alt="Alô Cidadão" height="100" />
            <Form schema={schema} onSubmit={handleSubmit}>
              <div style={styles.line}>
                <Input name="username" type="text" placeholder="Seu usuário" />
              </div>
              <div style={styles.line}>
                <Input
                  name="password"
                  type={visibility ? 'text' : 'password'}
                  placeholder="Sua senha"
                />
                <i style={styles.lente}>
                  {visibility ? (
                    <VisibilityOff onClick={() => setVisibility(!visibility)} />
                  ) : (
                    <Visibility onClick={() => setVisibility(!visibility)} />
                  )}
                </i>
                <ReactIsCapsLockActive>
                  {active => <span>{active && 'Caps Lock ativado'}</span>}
                </ReactIsCapsLockActive>
              </div>
              <button type="submit">
                {loading ? 'Carregando...' : 'Acessar'}
              </button>
            </Form>
            <div style={styles.buttonsDiv}>
              <FlatButton
                label="Esqueceu a senha?"
                onClick={handleClickOpen}
                // href="/recuperacao-de-senha"
                style={styles.flatButton}
                icon={<Help />}
              />
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open.open}
                onClose={handleClose}
              >
                {warning ? (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      {'Recuperação de senha'}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {failed
                          ? 'Erro ao validar o usuário. Entre em contato com o suporte.'
                          : 'Foi criado uma nova senha e enviada por email. Caso não tenha recebido ou perdido o acesso ao email cadastrado entre em contato com o suporte.'}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Ok
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogTitle>Recuperação de senha</DialogTitle>
                    <DialogContent>
                      <form className={classes.container}>
                        <TextField
                          id="username"
                          label="Usuário"
                          className={classes.textField}
                          onChange={handleChange('username')}
                          margin="normal"
                        />
                        <br />
                        <TextField
                          id="email"
                          label="Email"
                          className={classes.textField}
                          onChange={handleChange('email')}
                          margin="normal"
                        />
                        <br />
                        <TextField
                          id="nascimento"
                          label="Data de nascimento"
                          value={values.nascimento}
                          className={classes.textField}
                          onChange={handleChange('nascimento')}
                          margin="normal"
                          InputProps={{
                            inputComponent: MaskNascimento,
                          }}
                        />
                        {error && (
                          <Typography
                            className={classes.textField}
                            color="secondary"
                          >
                            Todos os campos devem ser preenchidos!!
                          </Typography>
                        )}
                      </form>
                    </DialogContent>
                    <DialogActions>
                      {progress ? (
                        <div className={classes.root}>
                          <LinearProgress />
                        </div>
                      ) : (
                        <>
                          <Button onClick={handleClose} color="primary">
                            Cancelar
                          </Button>
                          <Button onClick={handleRecovery} color="primary">
                            Recuperar
                          </Button>
                        </>
                      )}
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </div>
          </Paper>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default LoginPage;
