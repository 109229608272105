/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import FilterListIcon from '@material-ui/icons/FilterList';

import api from '~/services/api';

import { ContainerFilter } from './styles';

import logoMapa from '~/assets/logo-mapa.png';

const useStyles = makeStyles({
  fab: {
    marginTop: '75px',
    height: '100px',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100px',
  },
});

export default function Mapa() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [state, setState] = React.useState({ id: '', open: false });
  const [types, setTypes] = React.useState('');
  const [mount, setMount] = React.useState(false);
  const [solicitacoes, setSolicitacoes] = React.useState([]);
  const [filtroTipo, setFiltroTipo] = React.useState([]);
  const [valuesMap, setValuesMap] = React.useState({
    center: {
      lat: -12.2935,
      lng: -50.608584,
    },
    zoom: 5,
  });

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function handleFilterTicket(id) {
    if (id.id === '') {
      setTypes('');
    } else {
      const result = filtroTipo.find(type => type.id === parseInt(id.id));
      setTypes(result.tipo);
    }
    setState({ ...state, open: false });
  }

  const handleClickOpen = () => {
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    if (!mount) {
      api.get('group-map').then(response => {
        // if (response)
        if (response.data !== 1) {
          const map = {
            center: {
              lat: parseFloat(response.data.lat),
              lng: parseFloat(response.data.long),
            },
            zoom: parseInt(response.data.zoom),
          };
          setValuesMap(map);
        }

        api.get('ticket').then(resp => {
          const ticket = [];
          resp.data.map(t => {
            const solicitacao = {
              id: t.id,
              lat: t.lat,
              long: t.lng,
              tipo: t.atividade.nome,
              descricao: t.referencia,
            };

            ticket.push(solicitacao);
          });

          setSolicitacoes(ticket);
        });

        api.get(`activities-groups?user=${profile.id}`).then(a => {
          const activity = [];
          a.data.map(atividade => {
            const atividadeGrupo = {
              id: atividade.atividade.id,
              tipo: atividade.atividade.nome,
            };

            activity.push(atividadeGrupo);
          });
          setFiltroTipo(activity);
        });

        setMount(true);
      });
    }
  }, [filtroTipo, mount, profile.id]);

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      {mount && (
        <>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyDSp_yy3zd-yk5M1MUO67MXydvhkL8O2P8',
            }}
            defaultCenter={valuesMap.center}
            defaultZoom={valuesMap.zoom}
          >
            {types === ''
              ? solicitacoes.map(solicitacao => (
                  <Tooltip
                    key={solicitacao.id}
                    lat={solicitacao.lat}
                    lng={solicitacao.long}
                    text={solicitacao.tipo}
                    title={
                      <>
                        <Typography color="inherit">
                          {solicitacao.tipo}
                        </Typography>
                        <b>{solicitacao.descricao}</b>
                      </>
                    }
                  >
                    <p>
                      <img src={logoMapa} alt="Alô Cidadão" />
                    </p>
                  </Tooltip>
                ))
              : solicitacoes.map(solicitacao =>
                  solicitacao.tipo === types ? (
                    <Tooltip
                      lat={solicitacao.lat}
                      lng={solicitacao.long}
                      text={solicitacao.tipo}
                      title={
                        <>
                          <Typography color="inherit">
                            {solicitacao.tipo}
                          </Typography>
                          <b>{solicitacao.descricao}</b>
                        </>
                      }
                    >
                      <p>
                        <img src={logoMapa} alt="Alô Cidadão" />
                      </p>
                    </Tooltip>
                  ) : (
                    ''
                  )
                )}
          </GoogleMapReact>
          <ContainerFilter>
            <>
              <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
                <FilterListIcon />
              </Fab>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={state.open}
                onClose={handleClose}
              >
                <DialogTitle>Selecione o Tipo</DialogTitle>
                <DialogContent>
                  <form className={classes.container}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
                      <Select
                        native
                        value={state.id}
                        onChange={handleChange('id')}
                        input={<Input id="age-native-simple" />}
                      >
                        <option value="" />
                        {filtroTipo.map(tipo => (
                          <option value={tipo.id}>{tipo.tipo}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => handleFilterTicket(state)}
                    color="primary"
                  >
                    Filtrar
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          </ContainerFilter>
        </>
      )}
    </div>
  );
}
