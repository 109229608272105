/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import Lightbox from '../lib';

import api from '~/services/api';

import logoMapa from '~/assets/logo-mapa.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  spacer: {
    flex: '1 1 30%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '96%',
  },
  menu: {
    width: 200,
  },
}));

const LocaleTicket = ({ text }) => <div>{text}</div>;

export default function EditarChamado(props) {
  const imagesResp = [];
  const { id } = props.match.params;
  const classes = useStyles();
  const [images, setImages] = React.useState([]);
  const [mount, setMount] = React.useState(false);
  const [ticket, setTicket] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [secretariats, setSecretariats] = React.useState([]);
  const [workstations, setWorkstations] = React.useState([]);
  const [history, setHistory] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [center, setCenter] = React.useState({
    lat: -12.2935,
    lng: -50.608584,
  });
  const [tab, setTab] = React.useState(0);
  const [close, setClose] = React.useState({
    descricao: '',
  });
  const [values, setValues] = React.useState({
    id_atividade: '',
    id_posto: '',
    id_secretaria: '',
  });

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeClose = name => event => {
    setClose({ [name]: event.target.value });
  };

  function handleSave() {
    setError(false);
    const ticketSave = { ...values, ...close };
    api.put(`ticket/${id}`, ticketSave).then(response => {
      if (response.data.ok) {
        toast.info(response.data.ok, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }

      if (response.data.error) {
        toast.error(response.data.error, {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    });
  }

  function handleSaveClose() {
    if (close.descricao === '') {
      setError(true);
    } else {
      handleSave();
    }
  }

  useEffect(() => {
    if (!mount) {
      api.get('functions').then(response => {
        setEdit(response.data.solicitacoes_editar);
      });
      api.get(`ticket/${id}`).then(response => {
        setTicket(response.data);
        const locale = {
          lat: parseFloat(response.data.lat),
          lng: parseFloat(response.data.lng),
        };
        const value = {
          id_atividade: response.data.atividade.id,
          id_posto: response.data.posto.id,
          id_secretaria: response.data.secretaria.id,
        };
        setCenter(locale);
        setValues(value);
        api.get(`ticket-close/${id}`).then(resp => {
          if (resp.data !== null) {
            setClose(resp.data);
          }
        });
      });
      api.get(`ticket-file/${id}`).then(t => {
        setImages(t.data);
      });
      api.get(`history/${id}`).then(h => {
        setHistory(h.data);
      });

      setMount(true);
    }

    if (ticket.length !== 0) {
      api.get(`activities-groups/${ticket.id_grupo}`).then(a => {
        setActivities(a.data);
      });

      api.get(`secretariats-groups/${ticket.id_grupo}`).then(s => {
        setSecretariats(s.data);
      });

      api.get(`workstations`).then(w => {
        setWorkstations(w.data);
      });
    }
  }, [ticket.length, mount, id, center, ticket, images, values, history]);

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Chamado" />
          <Tab label="Fotos" />
          <Tab label="Mapa" />
          {edit && <Tab label="Encerramento" />}
        </Tabs>
      </Paper>
      {tab === 0 && (
        <Paper className={classes.paper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Detalhes do Chamado
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/solicitacoes">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
            {edit && (
              <div className={classes.actions}>
                <Tooltip title="Salvar Solicitação">
                  <IconButton
                    aria-label="Salvar Solicitação"
                    onClick={() => handleSave()}
                  >
                    <Save color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Toolbar>
          {ticket.length !== 0 && (
            <form className={classes.container} noValidate autoComplete="off">
              <Grid item xs={12} sm={4}>
                <TextField
                  id="id_atividade"
                  select
                  label="Atividade"
                  className={classes.textField}
                  value={values.id_atividade}
                  onChange={handleChange('id_atividade')}
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  disabled={!edit}
                >
                  {activities.map(activity => (
                    <option
                      key={activity.atividade.id}
                      value={activity.atividade.id}
                    >
                      {activity.atividade.nome}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Aberto Por"
                  className={classes.textField}
                  value={ticket.usuario.nome}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Endereço"
                  className={classes.textField}
                  value={ticket.endereco}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Referência"
                  className={classes.textField}
                  value={ticket.referencia}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Descrição"
                  multiline
                  className={classes.textField}
                  value={ticket.descricao}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </form>
          )}
          <>
            <Toolbar>
              <div className={classes.title}>
                <Typography variant="h6" id="tableTitle">
                  Histórico do Chamado
                </Typography>
              </div>
            </Toolbar>
            <Grid container spacing={1}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Criado</TableCell>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Posto</TableCell>
                    <TableCell align="left">Secretaria</TableCell>
                    <TableCell align="left">Operador</TableCell>
                    <TableCell align="left">Executor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {format(
                          parseISO(row.createdAt),
                          "dd'/'MM'/'yyyy HH:mm",
                          {
                            locale: pt,
                          }
                        )}
                      </TableCell>
                      <TableCell align="left">{row.atividade.nome}</TableCell>
                      <TableCell align="left">{row.posto.nome}</TableCell>
                      <TableCell align="left">{row.secretaria.nome}</TableCell>
                      <TableCell align="left">{row.usuario.nome}</TableCell>
                      <TableCell align="left">
                        {row.executor ? row.executor.nome : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </>
        </Paper>
      )}
      {tab === 1 && (
        <Paper className={classes.paper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Fotos do Chamado
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/solicitacoes">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
          </Toolbar>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Typography className={classes.paper}>
                Fotos da Abertura
              </Typography>
            </Grid>
            <div>
              {images.length === 0 ? (
                <Typography>
                  Sem fotos de abertura para esta solicitação
                </Typography>
              ) : (
                <Lightbox
                  images={images}
                  thumbnailWidth="200px"
                  thumbnailHeight="200px"
                />
              )}
            </div>
            <Grid item xs={12}>
              <Typography className={classes.paper}>
                Fotos do Encerramento
              </Typography>
            </Grid>
            <div>
              {imagesResp.length === 0 ? (
                <Typography>
                  Sem fotos de encerramento para esta solicitação
                </Typography>
              ) : (
                <Lightbox
                  images={imagesResp}
                  thumbnailWidth="200px"
                  thumbnailHeight="200px"
                />
              )}
            </div>
          </Paper>
        </Paper>
      )}
      {tab === 2 && (
        <Paper className={classes.paper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Localização do Chamado
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/solicitacoes">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
          </Toolbar>
          <div style={{ height: '70vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDSp_yy3zd-yk5M1MUO67MXydvhkL8O2P8',
              }}
              defaultCenter={center}
              defaultZoom={18}
            >
              <LocaleTicket
                lat={ticket.lat}
                lng={ticket.lng}
                text={<img src={logoMapa} alt="Alô Cidadão" />}
              />
            </GoogleMapReact>
          </div>
        </Paper>
      )}
      {tab === 3 && (
        <Paper className={classes.paper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Encerramento do Chamado
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Link to="/solicitacoes">
                <Tooltip title="Cancelar">
                  <IconButton aria-label="Cancelar">
                    <Close color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
            <div className={classes.actions}>
              <Tooltip title="Salvar Encerramento">
                <IconButton
                  aria-label="Salvar Encerramento"
                  onClick={() => handleSaveClose()}
                >
                  <Save color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid item xs={12} sm={6}>
              <TextField
                id="id_secretaria"
                select
                label="Secretaria"
                className={classes.textField}
                value={values.id_secretaria}
                onChange={handleChange('id_secretaria')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                disabled={!edit}
              >
                {secretariats.map(secretariat => (
                  <option
                    key={secretariat.secretaria.id}
                    value={secretariat.secretaria.id}
                  >
                    {secretariat.secretaria.nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="id_posto"
                select
                label="Posto"
                className={classes.textField}
                value={values.id_posto}
                onChange={handleChange('id_posto')}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                disabled={!edit}
              >
                {workstations.map(workstation => (
                  <option key={workstation.id} value={workstation.id}>
                    {workstation.nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="descricao"
                name="descricao"
                label="Descrição"
                multiline
                className={classes.textField}
                value={close.descricao}
                margin="normal"
                variant="outlined"
                onChange={handleChangeClose('descricao')}
                error={error}
                helperText={error && 'Descrição deve ser preenchida'}
                disabled={!edit}
              />
            </Grid>
          </form>
        </Paper>
      )}
    </>
  );
}
