/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Group from '@material-ui/icons/Group';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import AccountBox from '@material-ui/icons/AccountBox';
import Domain from '@material-ui/icons/Domain';
import DateRange from '@material-ui/icons/DateRange';
import Map from '@material-ui/icons/Map';
import ViewList from '@material-ui/icons/ViewList';
import Dashboard from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { confirmAlert } from 'react-confirm-alert';
import { signOut } from '~/store/modules/auth/actions';
import 'react-confirm-alert/src/react-confirm-alert.css';

import api from '~/services/api';

import logo from '~/assets/logo.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  profile: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  customUi: {
    textAlign: 'center',
    width: '500px',
    padding: '40px',
    background: '#3f51b5',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#fff',
  },
  customUiH1: {
    marginTop: '0',
  },
  customUiP: {
    display: 'block',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
  },
  customUiButton: {
    width: '160px',
    padding: '10px',
    border: '1px solid #fff',
    margin: '10px',
    cursor: 'pointer',
    background: 'none',
    color: '#fff',
    fontSize: '14px',
  },
  linkProfile: {
    textDecoration: 'none',
    color: '#FFF',
  },
  linkProfileMobile: {
    textDecoration: 'none',
    color: '#000',
  },
}));

export default function MenuBar() {
  const profile = useSelector(state => state.user.profile);
  const classes = useStyles();
  const [menu, setMenu] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [alertLogout, setAlertLogout] = React.useState(false);
  const dispatch = useDispatch();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleSingOut() {
    dispatch(signOut());
  }

  useEffect(() => {
    function loadMenuMount() {
      api
        .get('menu')
        .then(response => {
          return setMenu(response.data);
        })
        .catch(() => {
          setAlertLogout(true);
        });
    }
    if (menu.length === 0) {
      loadMenuMount();
    }
  }, [handleSingOut, menu]);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link
          to="/perfil"
          color="inherit"
          className={classes.linkProfileMobile}
        >
          <IconButton aria-label="perfil" color="inherit">
            <AccountCircle />
          </IconButton>
          Perfil
        </Link>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="sair" color="inherit" onClick={handleSingOut}>
          <ExitToApp />
        </IconButton>
        Sair
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {profile.grupo}
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link to="/perfil" color="inherit" className={classes.linkProfile}>
              <IconButton aria-label="perfil" color="inherit">
                <AccountCircle />
              </IconButton>
            </Link>
            <IconButton
              aria-label="sair"
              color="inherit"
              onClick={handleSingOut}
            >
              <ExitToApp />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {menu.menuItems ? (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img src={logo} alt="Alô Cidadão" height="50" width="150" />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {menu.menuItems.map(item => (
              <Link
                key={item.id}
                to={item.url}
                style={{ color: 'inherit' }}
                onClick={() => handleDrawerClose()}
              >
                <ListItem button key={item.id}>
                  <ListItemIcon>
                    {item.icone === 'Dashboard' ? (
                      <Dashboard />
                    ) : item.icone === 'DateRange' ? (
                      <DateRange />
                    ) : item.icone === 'Map' ? (
                      <Map />
                    ) : item.icone === 'ViewList' ? (
                      <ViewList />
                    ) : item.icone === 'LocalActivity' ? (
                      <ViewList />
                    ) : (
                      <MoveToInbox />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.nome} />
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
          {menu.menuItemsAdmins ? (
            <List>
              {menu.menuItemsAdmins.map(item => (
                <Link
                  key={item.id}
                  to={item.url}
                  style={{ color: 'inherit' }}
                  onClick={() => handleDrawerClose()}
                >
                  <ListItem button>
                    <ListItemIcon>
                      {item.icone === 'AccountBox' ? (
                        <AccountBox />
                      ) : item.icone === 'Domain' ? (
                        <Domain />
                      ) : item.icone === 'Group' ? (
                        <Group />
                      ) : (
                        <MoveToInbox />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={item.nome} />
                  </ListItem>
                </Link>
              ))}
            </List>
          ) : (
            ''
          )}
        </Drawer>
      ) : (
        ''
      )}
      {alertLogout &&
        confirmAlert({
          // eslint-disable-next-line react/prop-types
          customUI: ({ onClose }) => {
            return (
              <div className={classes.customUi}>
                <h1 className={classes.customUiH1}>Sua sessão expirou!</h1>
                <p className={classes.customUiP}>Necessário logar novamente</p>
                <button
                  className={classes.customUiButton}
                  onClick={() => {
                    handleSingOut();
                    onClose();
                  }}
                  // onClick={handleSingOut}
                >
                  Refazer login
                </button>
              </div>
            );
          },
        })}
    </div>
  );
}
